import React from "react";
import Avatar from "../Avatar/Avatar";
import { MdDelete } from "react-icons/md";

const Message = ({
  type,
  text,
  withAvatar,
  customClasses,
  userProfile,
  onDelete,
  isSending = false,
}) => {
  return (
    <div
      className={`${
        type === "left" ? "reciever" : "sender"
      } w-full flex items-center mt-1 ${customClasses}`}
    >
      {withAvatar ? (
        <Avatar image={userProfile} />
      ) : (
        <div className="w-[30px] h-[30px]"></div>
      )}
      <p className="px-3 py-1 rounded-lg max-w-[300px] text-[12px]  sm:text-[13px]  md:text-[14px] transition-all duration-300 ease-in">
        {text}
      </p>
      {type === "right" && !isSending && (
        <MdDelete
          onClick={onDelete}
          className=" cursor-pointer text-[--primary] hover:text-red-600"
        />
      )}
    </div>
  );
};

export default Message;
