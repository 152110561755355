import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch } from "react-redux";
import { changeTaskStatus } from "../../Redux/Actions";
import { useFormik } from "formik";
import { taskSchema } from "../../Schema";
// import { errorToast } from "../../Utils/Toast";

const UpdateTaskStatus = ({ open, handleClose, data }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  console.log(`UpdateTaskStatus`, data);
  const initialState = {
    id: data.id,
    note: "",
    staffId: data.staffId,
  };

  const updateStatusData = {
    staffId: data.staffId,
    taskId: data.taskId,
    status: data.status,
    note: "",
  };

  function updatestatus() {
    dispatch(changeTaskStatus(updateStatusData)).then(() => {
      setLoading(false);
      handleClose();
    });
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: taskSchema,
      onSubmit: (values) => {
        setLoading(true);
        updateStatusData.note = values.note;
        updatestatus();
      },
    });

  return (
    <Modal open={open} onclick={handleClose} headerText="Update Task">
      <div className="flex justify-start items-start flex-col">
        <label
          htmlFor="note"
          className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
        >
          Add Note
        </label>
        <textarea
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.note}
          name="note"
          id="note"
          className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-md focus:border-gray-800 block w-full px-2.5 py-2"
          rows="7"
        ></textarea>
        {touched.note && errors.note ? (
          <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
            {errors.note}
          </span>
        ) : null}
      </div>
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2"}
              onClick={handleClose}
            />
            <ButtonSmall
              onClick={handleSubmit}
              type="button"
              text="Update"
              customClasses={"!w-1/2 "}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpdateTaskStatus;
