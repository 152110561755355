import React from "react";
import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";

const Modal = ({ headerText, open, onclick, children }) => {
  return (
    <div
      className={`${
        open
          ? "w-full h-full fixed top-0 left-0 z-50 flex justify-center items-center modalBackground  "
          : "hidden"
      } transition-all duration-700 ease-in-out`}
    >
      <div className="w-[90%] sm:w-[75%] md:w-[65%] lg:w-[800px] p-6 bg-white rounded-md max-h-[90vh]">
        <div className="w-full mx-auto py-3 flex justify-end items-center ">
          <div className="w-[60%] flex justify-between items-center">
            <h5 className="satoshi-900 text-xl capitalize">{headerText}</h5>
            <button onClick={onclick} className="p-4  text-2xl">
              <MdOutlineCancel />
            </button>
          </div>
        </div>
        <div className="w-full py-5">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
