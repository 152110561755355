const initialState = {
  data: null,
  isInvoked: false,
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_DATA":
      return {
        ...state,
        data: payload.data,
        isInvoked: true,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
