import axios from "axios";
import { successToast, errorToast } from "../../Utils/Toast";
import { removeToken, setToken } from "../../Utils/tokenutil";
import api from "./axiosInstance";
import { formatDateArray, formatDateSingle } from "../../Utils/formatDate";
let staffId = localStorage.getItem("staffId");

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   withCredentials: true,
//   headers: {
//     "Content-type": "application/json",
//     Accept: "application/json",
//   },
// });

export const login = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/login", payload);
    staffId = response.data.data.id;
    localStorage.setItem("staffId", response.data.data.id);
    setToken(response.data);
    dispatch({ type: "LOGIN", payload: response.data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = () => async () => {
  try {
    const response = await api.post("/logout");
    // dispatch({ type: "LOGOUT" });
    removeToken();
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const emailVerification = async (payload) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/forgotPassword",
      payload
    );
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode === 401) {
      errorToast("Invalid email address");
      throw error;
    } else {
      errorToast("Something went wrong");
      throw error;
    }
  }
};

export const verifyOtp = async (userId, payload) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + `/verifyCode/${userId}`,
      payload
    );
    console.log("Response ===>", response.data);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode === 400) {
      errorToast("Incorrect OTP");
      throw error;
    } else {
      errorToast("Something went wrong");
      throw error;
    }
  }
};

export const changePasswordVerify = async (payload) => {
  try {
    const response = await api.patch("/changePasswordVerify", {
      userId: payload.userId,
      password: payload.password,
    });
    successToast("Password updated successfully");
    return response.data;
  } catch (error) {
    errorToast("Something went wrong");
    throw error;
  }
};

export const updateSetting = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/updateMe", payload);
    dispatch({ type: "LOGIN", payload: response.data });
    successToast("Setting updated successfully");
    return;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const getDashboardData = () => async (dispatch) => {
  try {
    const response = await api.get(`/staffDashboard/${staffId}`);
    dispatch({ type: "FETCH_DATA", payload: response.data });
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const getTaskData = () => async (dispatch) => {
  try {
    const response = await api.get(`/getAssociateTask/${staffId}`);
    if (response.data.data.length) {
      dispatch({ type: "FETCH_TASKS", payload: response.data });
    }
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const changeTaskStatus = (payload) => async (dispatch) => {
  try {
    payload.staffId = staffId;
    const response = await api.patch("/changeTaskStatus", payload);
    successToast("Task status updated successfully");
    dispatch(getTaskData());
    return;
  } catch (error) {
    console.log(error);
    errorToast("Somthing went wrong");
  }
};

export const addNote = async (payload) => {
  try {
    payload.staffId = staffId;
    const response = await api.patch("/addNotes", payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getClientsGoals = () => async (dispatch) => {
  try {
    const response = await api.get(`/getGoals/Staff/${staffId}`);
    dispatch({ type: "FETCH_GOALS", payload: response.data });
    return;
  } catch (error) {
    console.log("Error get goals ===>", error);
    errorToast("Something went wrong");
  }
};

export const createGoal = (payload) => async (dispatch) => {
  try {
    payload.staff.push(staffId);
    const response = await api.post("/Goal", payload);
    successToast("Goal created successfully");
    dispatch(getClientsGoals());
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const updateGoal = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/Goal", payload);
    successToast("Goal updated successfully");
    dispatch(getClientsGoals());
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const addGoalSteps = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/Goal", payload);
    successToast("Goal step updated successfully");
    dispatch(getClientsGoals());
    return;
  } catch (error) {
    console.log("Error add goals ===>", error);
    errorToast("Something went wrong");
  }
};

export const deleteGoal = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/Goal?id=${payload}`);
    successToast("Goal deleted successfully");
    dispatch(getClientsGoals());
    return;
  } catch (error) {
    console.log("Error add goals ===>", error);
    errorToast("Something went wrong");
  }
};

export const updateGoalStepsReopen = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/Goal", payload);
    return dispatch(reOpenGoal(payload.goalId));
  } catch (error) {
    console.log("Error add goals ===>", error);
    errorToast("Something went wrong");
  }
};

export const reOpenGoal = (payload) => async (dispatch) => {
  try {
    await api.post(`/ReOpenGoal/${payload}`);
    successToast("Goal reopened successfully");
    dispatch(getClientsGoals());
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const getAllEvents = () => async (dispatch) => {
  try {
    const response = await api.get(`/getSchedule/Staff/${staffId}`);
    dispatch({
      type: "FETCH_EVENTS",
      payload: formatDateArray(response?.data?.data),
    });
    return;
  } catch (error) {
    throw error;
  }
};

export const addNewEvent = (payload) => async (dispatch) => {
  try {
    payload.staffs = [staffId];
    const response = await api.post("/addSchedule", payload);
    successToast("Event created successfully");
    dispatch(getAllEvents());
    // dispatch({
    //   type: "ADD_EVENT",
    //   payload: formatDateSingle(response?.data?.data),
    // });
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const updateEvent = (payload, id) => async (dispatch) => {
  try {
    payload.staffs = [staffId];
    const response = await api.patch(`/Schedule?id=${id}`, payload);
    successToast("Event updated successfully");

    dispatch(getAllEvents());
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    const response = await api.delete(`/Schedule?id=${id}`);
    successToast("Event deleted successfully");
    dispatch(getAllEvents());
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const getClientDetail = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/ClientAllInfo?userId=${payload}`);
    dispatch({ type: "GET_CLIENT_DETAIL", payload: response.data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateClientSettings = (payload, userId) => async (dispatch) => {
  try {
    const response = await api.patch(`/updateUser/${userId}`, payload);
    successToast("Settings updated successfully");
    dispatch(getDashboardData());
  } catch (error) {
    console.log("Error updating client profile", error);
    errorToast("Something went wrong");
  }
};

export const deleteUser = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/deleteUser/${payload.userId}`);
    dispatch({ type: "DELETE_CLIENT", payload: { _id: payload.userId } });

    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const viewOrDownloadClientReport = async (payload) => {
  try {
    const response = await api.get(`/clientReport/${payload}`);
    return response.data;
  } catch (error) {
    errorToast("Something went wrong");
    throw error;
  }
};

export const allowAccessToClient = (payload) => async (dispatch) => {
  try {
    payload.staffId = staffId;
    const response = await api.post("/AllowAccessToReport", payload);
    dispatch({ type: "SMART_PLAN_ACCESS_GRANTED" });
  } catch (error) {
    errorToast("Something went wrong");
    throw error;
  }
};

export const checkLogin = () => async (dispatch) => {
  const refreshToken = localStorage.getItem("st_refresh");

  try {
    if (refreshToken) {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/refreshToken`,
        {
          refreshToken: JSON.parse(refreshToken),
        }
      );
      staffId = response.data.data.id;
      localStorage.setItem("staffId", response.data.data.id);
      setToken(response.data);
      dispatch({ type: "LOGIN", payload: response.data });
      return response.data;
    }
    return;
  } catch (error) {
    dispatch({ type: "LOGOUT" });
  }
};

export const uploadPhoto = async (payload) => {
  try {
    const formData = new FormData();
    formData.append("photo", payload.photo);
    const response = await api.post("/photo", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    errorToast("Something went wrong while uploading photo");
    throw error;
  }
};

//  ===============================================
//              Chat Api
//  ===============================================
export const getAllUsers = () => async (dispatch) => {
  try {
    const response = await api.get(`/getUsersToChat/Staff/${staffId}`);
    dispatch({ type: "CHAT_ALL_USER", payload: response.data });
    return;
  } catch (error) {
    console.error(error);
  }
};

export const getListedChat = () => async (dispatch) => {
  try {
    const response = await api.get(`/getMessages/${staffId}`);
    dispatch({ type: "CHAT_LISTING", payload: response.data });
    return;
  } catch (error) {
    console.error(error);
  }
};

export const sendMessage = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/sendMessage`, payload);
    dispatch({
      type: "ADD_SEND_MSG",
      payload: response.data,
    });
    return response;
  } catch (error) {
    console.error(error);
    errorToast("Message sending failed");
    throw error;
  }
};

export const readMessage = async (payload) => {
  try {
    const response = await api.post(`/readMessage`, payload);
    return;
  } catch (error) {
    console.error(error);
  }
};

export const deleteChat = (messages, targetChat) => async (dispatch) => {
  try {
    const response = await api.post("/deleteMessage", {
      messageId: messages,
      type: "chat",
    });
    dispatch({
      type: "DELETE_CHAT",
      payload: { id: targetChat },
    });
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong while deleting chat");
  }
};

export const deleteSingleMessage = (paylaod) => async (dispatch) => {
  try {
    const response = await api.post("/deleteMessage", {
      messageId: paylaod.message,
      type: "message",
    });
    dispatch({
      type: "DELETE_MESSAGE",
      payload: { senderId: paylaod.targetChat, messageId: paylaod.message[0] },
    });
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong while deleting message");
  }
};

export const getUserUnreadMessages = async () => {
  try {
    const response = await api.get(`getUnreadCount/${staffId}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const changeSelfPassword = async (payload) => {
  try {
    await api.patch("/changePassword", payload);
    successToast("Password changed successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast(error?.response?.data?.message || "Something went wrong");
  }
};

export const changeClientPassword = async (payload) => {
  try {
    await api.patch(`/updateUser/${payload.id}`, {
      password: payload.password,
    });
    successToast("Password changed successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast(error?.response?.data?.message || "Something went wrong");
  }
};


// coach email update
export const updateEmail = (payload) => async (dispatch) => {
  try {
    const res = await api.patch(`/updateUser/${payload.userId}`, {
      email: payload.newEmail,
    })
    return res;
  } catch (error) {
    console.log('Error in updateEmail', error);
  }
}