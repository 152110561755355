import React, { useEffect, useRef, useState } from "react";
import {
  getAllUsers,
  getListedChat,
  readMessage,
  sendMessage,
} from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import HeaderData from "../../components/Header/HeaderData";
import Loader from "../../components/Loading/Loader";
import { useSocket } from "../../context/socketContext";
import TablesWrapper from "../../components/Wrapper/TablesWrapper";
import ChatListing from "../../components/Table/ChatListing";
import Message from "../../components/Message/Message";
import { GrSend } from "react-icons/gr";
import Avatar from "../../components/Avatar/Avatar";
import DeleteMessage from "../../components/Modal/DeleteMessage";
import DeleteChat from "../../components/Modal/DeleteChat";

const Inbox = () => {
  let Once = true;
  const dropdownRef = useRef(null);
  const socket = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [laoding, setLoading] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const staffData = useSelector((state) => state.auth.userData);
  const chatListing = useSelector((state) => state.chatListing.data);
  const allUser = useSelector((state) => state.allChatUser.data);
  const messagesContainerRef = useRef(null);
  const [isMessageSend, setIsMessageSend] = useState(false);
  const [selectedChat, setSelectedChat] = useState({
    status: false,
    userData: null,
  });
  const [newMessageData, setNewMessagedata] = useState({
    message: "",
    createdAt: new Date(),
    read: true,
    direction: {
      type: "right",
      user: {
        _id: staffData?._id,
        firstName: staffData?.firstName,
        lastName: staffData?.lastName,
        photo: staffData?.photo,
      },
    },
  });

  const [deleteMessage, setDeleteMessage] = useState({
    modal: false,
    data: null,
  });

  const [deleteChat, setDeleteChat] = useState({
    modal: false,
    data: null,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleChatDelete = (chatSenderId) => {
    if (selectedChat?.userData?._id?.sender?._id === chatSenderId) {
      setSelectedChat({
        status: false,
        userData: null,
      });
    }
  };

  function removeMessage(data) {
    dispatch({ type: "REMOVE_MESSAGE", payload: data });
    if (
      selectedChat?.status &&
      selectedChat?.userData?._id?.sender?._id === data?._id?.sender?._id
    ) {
      setSelectedChat((pre) => {
        return {
          ...pre,
          userData: {
            ...pre.userData,
            messages: pre.userData.messages.filter(
              (message) => message._id !== data.message
            ),
          },
        };
      });
    }
  }

  const handleDeleteMessage = (messageId) => {
    setSelectedChat((prev) => ({
      ...prev,
      userData: {
        ...prev.userData,
        messages: prev.userData.messages.filter(
          (message) => message._id !== messageId
        ),
      },
    }));
  };

  function handleSendMessage(e) {
    e.preventDefault();
    if (!newMessageData?.message) {
      return;
    } else {
      setIsMessageSend(true);
      const body = {
        sender: staffData._id,
        receiver: selectedChat?.userData?._id?.sender?._id,
        message: newMessageData.message,
      };
      setSelectedChat((pre) => {
        return {
          ...pre,
          userData: {
            ...pre.userData,
            messages: [
              ...pre.userData.messages,
              {
                isSending: true,
                ...newMessageData,
              },
            ],
          },
        };
      });
      setNewMessagedata((pre) => {
        return {
          ...pre,
          message: "",
        };
      });

      scrollToBottom();
      dispatch(sendMessage(body))
        .then((res) => {
          setSelectedChat((pre) => {
            return {
              ...pre,
              userData: {
                ...pre.userData,
                messages: pre.userData.messages.map((message) => {
                  if (message.isSending) {
                    return {
                      ...message,
                      _id: res.data.data._id,
                      isSending: false,
                    };
                  } else {
                    return message;
                  }
                }),
              },
            };
          });
        })
        .catch((err) => {
          setSelectedChat((pre) => {
            return {
              ...pre,
              userData: {
                ...pre.userData,
                messages: pre.userData.messages.filter(
                  (message) => !message.isSending
                ),
              },
            };
          });
        });
    }
  }

  function geChattListing() {
    if (isEmpty(chatListing)) {
      setListLoader(true);
      dispatch(getListedChat()).then(() => {
        setListLoader(false);
      });
    }
  }

  async function getAllUser() {
    if (isEmpty(allUser)) {
      setLoading(true);
      dispatch(getAllUsers()).then(() => {
        setLoading(false);
        return;
      });
    }
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleMessage = (data) => {
      if (chatListing.length) {
        const targetChat = chatListing.find(
          (chat) => chat._id.sender._id === data?._id?.sender?._id
        );
        if (!targetChat) {
          dispatch({ type: "ADD_NEW_CHAT", payload: data });
        } else {
          if (
            selectedChat?.status &&
            selectedChat?.userData?._id?.sender?._id === data?._id?.sender?._id
          ) {
            // Update the selected chat messages if the incoming message belongs to the currently selected chat
            setSelectedChat((prev) => ({
              ...prev,
              userData: {
                ...prev.userData,
                messages: [...prev.userData.messages, data.messages[0]],
              },
            }));
            readMessage({
              sender: selectedChat?.userData?._id?.sender?._id,
              receiver: staffData?._id,
            });
            dispatch({ type: "NEW_MESSAGE_READ", payload: data });
          } else {
            // If the message does not belong to the selected chat, just update the chat listing
            dispatch(getListedChat(staffData._id));
          }
        }
      } else {
        dispatch({ type: "ADD_NEW_CHAT", payload: data });
      }
    };

    scrollToBottom();

    socket.on("message", handleMessage);
    socket.on("messageDeleted", removeMessage);
    return () => {
      socket.off("message", handleMessage);
      socket.off("messageDeleted", removeMessage);
    };
  }, [chatListing, selectedChat]);

  function scrollToBottom() {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }

  useEffect(() => {
    scrollToBottom();
    if (!isMessageSend) {
      if (selectedChat?.userData?.unread > 0) {
        readMessage({
          sender: selectedChat?.userData?._id?.sender?._id,
          receiver: staffData?._id,
        }).then(() => {
          dispatch(getListedChat());
        });
      }
    }
  }, [selectedChat]);

  useEffect(() => {
    if (Once) {
      Once = false;
      getAllUser().then(() => {
        geChattListing();
      });
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <HeaderData text="Inbox" />
      {laoding ? (
        <Loader />
      ) : (
        <>
          {allUser?.length ? (
            <div className="relative group overflow-visible flex justify-end">
              <button
                onClick={toggleDropdown}
                className="relative inline-flex justify-center w-full sm:w-[70%] md:w-[50%] px-4 py-2 text-sm sm:text-[16px] font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm"
              >
                <span className="mr-2"> Start new conversation</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {isOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute z-50 right-0 mt-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 max-h-[500px] w-full sm:w-[70%] md:w-[50%] overflow-y-auto"
                >
                  <input
                    type="text"
                    placeholder="Search user"
                    className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                    onChange={handleSearch}
                    autoComplete="off"
                  />
                  {allUser
                    .filter((user) =>
                      `${user?.firstName} ${user?.lastName}`
                        .toLowerCase()
                        .includes(searchTerm)
                    )
                    .map((user, index) => (
                      <div
                        key={index}
                        className="block px-4 py-2 border-b text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                        onClick={() => navigate(`/messages/${user?._id}`)}
                      >
                        {user?.firstName} {user?.lastName}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ) : null}
          <TablesWrapper customClasses="!mb-1 !py-2 md:flex-row">
            <div className="w-full md:w-[30%] bg-white rounded-md ">
              <div className="relative mx-auto min-w-[100px] w-[90%] text-[--gray] bg-white border rounded-full my-2">
                <span className="absolute inset-y-0 left-0 flex items-center pl-1 sm:pl-2">
                  <button
                    type="submit"
                    className="p-1 focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-3 h-4"
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </button>
                </span>
                <input
                  type="search"
                  name="q"
                  className="py-1 md:text-[12px] lg:text-[14px] w-[98%] bg-inherit rounded-md pl-8 outline-none text-black"
                  placeholder="Search User"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
              </div>

              {listLoader ? (
                <div className=" h-[58vh] flex justify-center items-center ">
                  <Loader />
                </div>
              ) : chatListing?.length ? (
                <div className="h-[59vh] bg-white">
                  {chatListing
                    .sort((a, b) => b.unread - a.unread)
                    .filter((user) =>
                      `${user?._id?.sender?.firstName} ${user?._id?.sender?.lastName}`
                        .toLowerCase()
                        .includes(searchQuery)
                    )
                    .map((chat, index) => {
                      return (
                        <ChatListing
                          key={index}
                          data={chat}
                          active={
                            chat?._id?.sender?._id ===
                            selectedChat?.userData?._id?.sender?._id
                          }
                          onClick={() => {
                            setIsMessageSend(false);
                            setSelectedChat({
                              status: true,
                              userData: chat,
                            });
                          }}
                          onDelete={() => {
                            setDeleteChat({
                              modal: true,
                              data: chat,
                            });
                          }}
                        />
                      );
                    })}
                </div>
              ) : (
                <div className="min-h-[60vh] flex justify-center items-center">
                  <p className="px-3 text-slate-300 text-[23px]">
                    No chat history found
                  </p>
                </div>
              )}
            </div>
            <div className="hidden md:block w-full md:w-[70%] bg-white rounded-lg">
              {selectedChat.status ? (
                <>
                  <div className="w-full hidden md:flex justify-between items-center py-2 border-b">
                    <div className="md:w-[65%] lg:w-[60%] xl:w-1/2 flex justify-start items-center px-3">
                      <Avatar
                        image={selectedChat?.userData?._id?.sender?.photo}
                      />
                      <div className=" ml-2 flex justify-center items-center flex-col ">
                        <h5 className="satoshi-500 text-[12px] lg:text-[14px] w-max">
                          {selectedChat?.userData?._id?.sender?.firstName +
                            " " +
                            selectedChat?.userData?._id?.sender?.lastName}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="h-[61vh] bg-white">
                    <div className="h-full  flex justify-end items-end flex-col">
                      {selectedChat?.userData?.messages?.length ? (
                        <div
                          className="my-2 w-full overflow-y-auto px-2"
                          ref={messagesContainerRef}
                        >
                          {selectedChat?.userData?.messages?.map(
                            (message, index) => {
                              if (
                                index > 0 &&
                                selectedChat?.userData?.messages[index - 1]
                                  ?.direction?.type === message?.direction?.type
                              )
                                return (
                                  <Message
                                    key={index}
                                    type={message?.direction?.type}
                                    text={message?.message}
                                    userProfile={
                                      message?.direction?.user?.photo
                                    }
                                    isSending={message?.isSending}
                                    customClasses={"!mt-[1px]"}
                                    onDelete={() => {
                                      if (!message._id) {
                                        return;
                                      }
                                      setDeleteMessage({
                                        modal: true,
                                        data: {
                                          message: [message._id],
                                          targetChat:
                                            selectedChat.userData._id.sender
                                              ._id,
                                        },
                                      });
                                    }}
                                  />
                                );
                              else
                                return (
                                  <Message
                                    key={index}
                                    customClasses={"!mt-8"}
                                    withAvatar={true}
                                    type={message?.direction?.type}
                                    text={message?.message}
                                    isSending={message?.isSending}
                                    userProfile={
                                      message?.direction?.user?.photo
                                    }
                                    onDelete={() => {
                                      if (!message._id) {
                                        return;
                                      }
                                      setDeleteMessage({
                                        modal: true,
                                        data: {
                                          message: [message._id],
                                          targetChat:
                                            selectedChat.userData._id.sender
                                              ._id,
                                        },
                                      });
                                    }}
                                  />
                                );
                            }
                          )}
                        </div>
                      ) : (
                        <div className="w-full h-full text-center flex justify-center items-center">
                          <h1 className="text-gray-300 text-[18px] sm:text-[22px] md:text-[25px] lg:text-[30px]">
                            You have no message with this user.
                            <br /> Say "hi" to start new conversation
                          </h1>
                        </div>
                      )}

                      <div className="w-full h-auto mb-2 overflow-hidden flex justify-center items-center !py-6">
                        <form
                          onSubmit={handleSendMessage}
                          className="flex  justify-between border items-center relative rounded-full bg-[--bg-yellow-light] w-[93%] pl-2 pr-4"
                        >
                          <input
                            type="text"
                            value={newMessageData.message}
                            name="message"
                            onChange={(e) => {
                              setNewMessagedata((pre) => {
                                return {
                                  ...pre,
                                  [e.target.name]: e.target.value,
                                };
                              });
                            }}
                            className="px-4 py-2 outline-none bg-inherit  w-[92%]"
                            placeholder="Write a message..."
                          />
                          <button
                            type="submit"
                            className="flex justify-center items-center text-[20px]"
                          >
                            <GrSend />
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="h-[68vh] bg-white flex justify-center items-center flex-col">
                  <h1 className="satoshi-900 px-3 text-[40px] text-gray-300">
                    Smartfbp Chat
                  </h1>
                  <p className="satoshi-700 px-3 text-[25px] text-gray-300">
                    Click user to start conversation
                  </p>
                </div>
              )}
            </div>
          </TablesWrapper>
        </>
      )}

      {deleteMessage.modal && (
        <DeleteMessage
          data={deleteMessage.data}
          open={deleteMessage.modal}
          handleModal={() => {
            setDeleteMessage({ modal: false, data: null });
          }}
          handleLocalDelete={handleDeleteMessage}
        />
      )}

      {deleteChat.modal && (
        <DeleteChat
          data={deleteChat.data}
          open={deleteChat.modal}
          handleModal={() => {
            setDeleteChat({ modal: false, data: null });
          }}
          handleLocalDelete={handleChatDelete}
        />
      )}
    </>
  );
};

export default Inbox;
