import React, { useEffect, useState } from "react";
import HeaderData from "../Header/HeaderData";
import DashboardFormWrapper from "../Wrapper/DashboardFormWrapper";
import InputWrapper from "../Wrapper/InputWrapper";
import InputSmall from "../Input/InputSmall";
import { FiCamera } from "react-icons/fi";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { updateClientProfile } from "../../Schema";
import ButtonLoading from "../Buttons/ButtonLoading";
import {
  getDashboardData,
  updateClientSettings,
  uploadPhoto,
} from "../../Redux/Actions";
import defaultProfile from "../../Assets/default-profile.png";
import { useParams } from "react-router-dom";
import Loader from "../Loading/Loader";
import ChangeUserPasswordModal from "../Modal/ChangeUserPasswordModal";

const ClientInfo = () => {
  let Once = true;
  const params = useParams();
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { data, isInvoked } = useSelector((state) => state.dashboard);
  const [passwordModal, setPasswordModal] = useState(false);
  const dispatch = useDispatch();

  const [initialState, setInitialState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    photo: "",
    clientInfo: {
      coachId: "",
      dob: "",
      occupation: "",
      phone: "",
      address: "",
    },
    role: "Client",
  });

  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: initialState,
    validationSchema: updateClientProfile,
    onSubmit: (values) => {
      if (profile) {
        setBtnLoading(true);
        uploadPhoto({ photo: profile })
          .then((res) => {
            values.photo = res?.data?.photos[0];
            dispatch(updateClientSettings(values, params?.userId)).then(() => {
              setBtnLoading(false);
            });
          })
          .catch((err) => {
            console.log(err);
            setBtnLoading(false);
          });
      } else {
        dispatch(updateClientSettings(values, params?.userId)).then(() => {
          setBtnLoading(false);
        });
      }
    },
  });

  function handleProfileChange(e) {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setProfile(selectedFile);
    }
  }

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  // Format the max date to be compatible with HTML date input format (YYYY-MM-DD)
  const maxDateString = maxDate.toISOString().split("T")[0];

  useEffect(() => {
    if (data?.clients?.length) {
      setLoading(true);
      let filteredUser = data?.clients.filter(
        (user) => user._id === params?.userId
      );
      const userData = filteredUser[0];
      const { firstName, lastName, email, photo, clientInfo } = userData;
      setValues({
        firstName: firstName,
        lastName: lastName,
        email: email,
        photo: photo,
        clientInfo: {
          coachId: clientInfo?.coachId,
          dob: clientInfo?.dob || "",
          occupation: clientInfo?.occupation || "",
          phone: clientInfo?.phone || "",
          address: clientInfo?.address || "",
        },
        role: "Client",
      });
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (Once) {
      Once = false;
      if (!isInvoked) {
        setLoading(true);
        dispatch(getDashboardData());
      }
    }
  }, []);

  return (
    <>
      <HeaderData text="Customer Profile" customClassess={"!my-3"} />
      {loading ? (
        <Loader />
      ) : (
        <DashboardFormWrapper customClasses="bg-white">
          <div className="flex justify-center items-center  p-3">
            <label htmlFor="profile" className="cursor-pointer relative py-3">
              <p className="block mb-1 pl-[2px] font-medium text-[14px] capitalize text-center">
                Profile Picture
              </p>
              <div className=" w-32 h-32 rounded-full border">
                {profile ? (
                  <img
                    className="w-full h-full"
                    src={URL.createObjectURL(profile)}
                    alt="profile "
                  />
                ) : (
                  <img
                    className="w-full h-full"
                    src={
                      values?.photo
                        ? process.env.REACT_APP_IMAGE_URL + values?.photo
                        : defaultProfile
                    }
                    alt="profile "
                  />
                )}
              </div>
              <input
                onChange={handleProfileChange}
                type="file"
                id="profile"
                hidden
                accept="image/*"
              />
              <div className="w-14 h-14 text-2xl flex justify-center items-center rounded-full absolute top-[68%] right-[0%] bg-[#2F80ED] text-white">
                <FiCamera />
              </div>
            </label>
          </div>
          <InputWrapper customClasses="!items-stretch">
            <InputSmall
              id="firstName"
              name="firstName"
              type="text"
              label="First Name"
              placeholder="John"
              value={values.firstName}
              error={errors.firstName}
              touch={touched.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputSmall
              id="lastName"
              name="lastName"
              type="text"
              label="Last Name"
              placeholder="Doe"
              value={values.lastName}
              error={errors.lastName}
              touch={touched.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <InputWrapper>
            <div className={`flex-grow basis-52`}>
              <label
                htmlFor="dob"
                className="block mb-1 pl-[2px] text-[16px] capitalize text-[#333333]"
              >
                Date of birth
              </label>
              <input
                type="date"
                name="clientInfo.dob"
                id="dob"
                max={maxDateString}
                value={values.clientInfo.dob}
                className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-md focus:border-gray-800 block w-full px-2.5 py-2 "
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.clientInfo &&
              touched.clientInfo.dob &&
              errors.clientInfo &&
              errors.clientInfo.dob ? (
                <p className="text-[14px] text-red-600">
                  {errors.clientInfo.dob}
                </p>
              ) : null}
            </div>
            <InputSmall
              id="occupation"
              name="clientInfo.occupation"
              type="text"
              label="Occupation"
              placeholder="i-e Engineer"
              value={values.clientInfo.occupation}
              error={errors.clientInfo && errors.clientInfo.occupation}
              touch={touched.clientInfo && touched.clientInfo.occupation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <InputWrapper>
            <InputSmall
              id="phone"
              name="clientInfo.phone"
              type="text"
              label="Phone"
              value={values.clientInfo.phone}
              error={errors.clientInfo && errors.clientInfo.phone}
              touch={touched.clientInfo && touched.clientInfo.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputSmall
              id="address"
              name="clientInfo.address"
              type="text"
              label="Address"
              placeholder="Enter address"
              value={values.clientInfo.address}
              error={errors.clientInfo && errors.clientInfo.address}
              touch={touched.clientInfo && touched.clientInfo.address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>

          <InputWrapper>
            <InputSmall
              id="email"
              name="email"
              type="email"
              label="Email"
              value={values?.email}
              placeholder="johndoe@gmail.com"
              disable={true}
            />
          </InputWrapper>

          <div className="mt-4 w-full pt-4 pb-6 flex justify-center items-center gap-3">
            {btnLoading ? (
              <div className="w-1/2 mx-auto">
                <ButtonLoading />
              </div>
            ) : (
              <>
                <ButtonSmall text="Update" onClick={handleSubmit} />
                <ButtonSmall
                  text="Update Password"
                  onClick={() => {
                    setPasswordModal(true);
                  }}
                />
              </>
            )}
          </div>
        </DashboardFormWrapper>
      )}
      {passwordModal && (
        <ChangeUserPasswordModal
          open={passwordModal}
          handleModal={() => {
            setPasswordModal(false);
          }}
          userId={params?.userId}
        />
      )}
    </>
  );
};

export default ClientInfo;
