import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import FluidCard from "../../components/Card/FluidCard";
import TablesWrapper from "../../components/Wrapper/TablesWrapper";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
// import ListRow from "../../components/Table/ListRow";
// import ButtonIcon from "../../components/Buttons/ButtonIcon";
// import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvents, getDashboardData } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import MyCalender from "../../components/Calender/MyCalender";
let Once = true;

const Dashboard = () => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (Once) {
      Once = false;
      if (!dashboardData.isInvoked) {
        setLoading(true);
        dispatch(getDashboardData()).then(() => {
          dispatch(getAllEvents()).then(() => {
            setLoading(false);
          });
        });
      } else {
        setLoading(true);
        dispatch(getAllEvents()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderData text="Dashboard" />
          <CardWrapper>
            <FluidCard
              withGraph={false}
              text="Total Task"
              value={
                dashboardData?.data?.lateTasks + dashboardData?.data?.dueTasks
              }
            />
            <FluidCard
              withGraph={false}
              text="Tasks late"
              value={dashboardData?.data?.lateTasks}
            />
            <FluidCard
              withGraph={false}
              text="Tasks due"
              value={dashboardData?.data?.dueTasks}
            />
            <FluidCard
              withGraph={false}
              text="Total clients"
              value={dashboardData?.data?.clientsCount}
            />

            {/* <FluidCard withGraph={false} text="New clients" value="40" /> */}
          </CardWrapper>
          <div className="mt-3 bg-white px-4 py-3 rounded-md">
            <MyCalender />
          </div>
          {/* {dashboardData?.data?.clients.length ? (
            <TablesWrapper customClasses={"md:flex-row"}>
              <div className="w-full px-3 bg-white rounded-lg">
                <TableHeader text="Clients" withSelect={false} />
                <div className="dashboardTableWrapper">
                  <table className="w-full min-w-[600px] border-collapse !overflow-x-auto">
                    <thead className="border-t border-b text-sm">
                      <tr>
                        <th style={thStyle} className="pl-2">
                          Name
                        </th>
                        <th style={thStyle}>Email</th>
                        <th style={thStyle}>Program</th>
                        <th style={thStyle}>Joining Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData.data.clients.map((client, index) => {
                        return <TableRow key={index} clientData={client} />;
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </TablesWrapper>
          ) : null} */}
        </>
      )}
    </>
  );
};

export default Dashboard;

const thStyle = {
  textAlign: "left",
  padding: "5px 3px",
  color: "var(--gray)",
};
