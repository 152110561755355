const initialState = {
  data: [],
  todo: 0,
  inProgress: 0,
  completed: 0,
};

function countTaskStatus(taskData, status) {
  const data = taskData.filter(
    (item) => item.curentStatus.toLowerCase() === status
  );
  return data.length;
}

const taskReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_TASKS":
      return {
        ...state,
        data: payload.data,
        todo: countTaskStatus(payload.data, "todo"),
        inProgress: countTaskStatus(payload.data, "inprogress"),
        completed: countTaskStatus(payload.data, "completed"),
      };

    case "UPDATE_TASK_STATUS": {
      console.log("UPDATE_TASK_STATUS ===>", payload);
      const updatedData = state.data.map((task) =>
        task._id === payload.taskId
          ? { ...task, curentStatus: payload.status }
          : task
      );
      return {
        ...state,
        data: updatedData,
        todo: countTaskStatus(updatedData, "todo"),
        inProgress: countTaskStatus(updatedData, "inprogress"),
        completed: countTaskStatus(updatedData, "completed"),
      };
    }

    default:
      return state;
  }
};

export default taskReducer;
