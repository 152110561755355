import { MdOutlineDashboard } from "react-icons/md";
import { FiMail, FiUser } from "react-icons/fi";
import { AiTwotoneSetting } from "react-icons/ai";
import { PiNoteDuotone } from "react-icons/pi";
import { HiOutlineClipboardCheck } from "react-icons/hi";

export const dashboardNavItems = [
  {
    label: "Dashboard",
    url: "/",
    icon: <MdOutlineDashboard />,
  },
  {
    label: "Inbox",
    url: "/inbox",
    icon: <FiMail />,
  },
  {
    label: "Task",
    url: "/task",
    icon: <PiNoteDuotone />,
  },
  {
    label: "User Goals",
    url: "/goals",
    icon: <HiOutlineClipboardCheck />,
  },
  {
    label: "Clients",
    url: "/clients",
    icon: <FiUser />,
  },
  {
    label: "settings",
    url: "/setting",
    icon: <AiTwotoneSetting />,
  },
];
