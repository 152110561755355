import React from "react";

const InputSmall = ({
  label,
  id,
  placeholder,
  type,
  name,
  value,
  disable,
  onChange,
  onBlur,
}) => {
  return (
    <div className="flex-grow basis-52">
      <label
        htmlFor={id}
        className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={id}
        value={value}
        onBlur={onBlur}
        disabled={disable}
        className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-md focus:border-gray-800 block w-full px-2.5 py-2 "
        placeholder={placeholder}
        onChange={onChange}
        required
      />
    </div>
  );
};

export default InputSmall;
