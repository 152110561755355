import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../Layouts/DashboardLayout";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Inbox from "../Pages/Dashboard/Inbox";
import Clients from "../Pages/Dashboard/Clients";
import Settings from "../Pages/Dashboard/Settings";

import Task from "../Pages/Dashboard/Task";
import Messages from "../Pages/Dashboard/Messages";
import SignIn from "../Pages/Auth/SignIn";
import { RedirectRoute } from "./RedirectRoute";
import { PrivateRoute } from "./PrivateRoute";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import UserGoals from "../Pages/Dashboard/UserGoals";
import CustomerDetails from "../Pages/Dashboard/CustomerDetails";
import SmartPlanDetail from "../Pages/Dashboard/SmartPlanDetail";

export default createBrowserRouter([
  {
    path: "/signin",
    element: (
      <RedirectRoute>
        <SignIn />
      </RedirectRoute>
    ),
  },
  {
    path: "/forget-password",
    element: (
      <RedirectRoute>
        <ForgetPassword />
      </RedirectRoute>
    ),
  },
  {
    path: "",
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "inbox",
        element: <Inbox />,
      },

      {
        path: "task",
        element: <Task />,
      },
      {
        path: "goals",
        element: <UserGoals />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "clients-detail/:userId",
        element: <CustomerDetails />,
      },
      {
        path: "smart-plan-detail/:id",
        element: <SmartPlanDetail />,
      },
      {
        path: "setting",
        element: <Settings />,
      },
      {
        path: "messages/:to",
        element: <Messages />,
      },
    ],
  },
]);
