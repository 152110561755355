import React from "react";

const ButtonIcon = ({ icon, text, onclick, type = "button" }) => {
  return (
    <div>
      <button
        onClick={onclick}
        type={type}
        className="px-2 sm:px-4 md:px-7 py-3 flex justify-center items-center border rounded-lg gap-2 md:gap-3 text-white bg-[--primary]"
      >
        {icon}
        <span className="text-[9px] sm:text-[12px] capitalize">{text}</span>
      </button>
    </div>
  );
};

export default ButtonIcon;
