import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import ClientRow from "../../components/Table/ClientRow";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loading/Loader";
import { getDashboardData } from "../../Redux/Actions";
let isInvoked = false;

const Clients = () => {
  const [loading, setLoading] = useState();
  const dashboardData = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isInvoked) {
      isInvoked = true;
      if (!dashboardData.isInvoked) {
        setLoading(true);
        dispatch(getDashboardData()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderData text="Clients" />
          {/* <ButtonIcon icon={<IoMdAdd />} text="Add a client" /> */}
          {/* </HeaderData> */}
          {dashboardData.data?.clients?.length ? (
            <div className="dashboardTableWrapper">
              <table className="bg-white w-full min-w-[900px] border-collapse !overflow-x-auto rounded-md mt-3">
                <thead>
                  <tr>
                    <th className="text-left px-2 pl-3 py-4 text-[--gray] text-sm">
                      Name
                    </th>
                    <th className="text-left px-2 py-4 text-[--gray] text-sm">
                      Email
                    </th>
                    <th className="text-left px-2 py-4 text-[--gray] text-sm">
                      No. of programs
                    </th>
                    <th className="text-left px-2 py-4 text-[--gray] text-sm">
                      Joining Date
                    </th>
                    {/* <th className="text-left px-2 py-4 text-[--gray] text-sm w-[60px]">
                      Package
                    </th> */}
                    {/* <th className="text-left px-2 py-4 text-[--gray] text-sm w-[60px]">
                      Meeting
                    </th> */}
                    <th className="text-left px-2 py-4 text-[--gray] text-sm">
                      Package
                    </th>
                    {/* <th className="text-right px-2 py-4 text-[--gray] text-sm w-[30px]"></th> */}
                  </tr>
                </thead>
                <tbody>
                  {dashboardData.data?.clients
                    .sort((a, b) => b?.joinedAt - a.joinedAt)
                    .map((client, index) => {
                      return <ClientRow key={index} data={client} />;
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <p>You don't have any client yet</p>
          )}
        </>
      )}
    </>
  );
};

export default Clients;
