import React, { useEffect, useState } from "react";
import ClientInfo from "../../components/CustomerTabs/ClientInfo";
import PaymentHistory from "../../components/CustomerTabs/PaymentHistory";
import Goals from "../../components/CustomerTabs/Goals";
import SmartPlan from "../../components/CustomerTabs/SmartPlan";
import Reports from "../../components/CustomerTabs/Reports";
import Loader from "../../components/Loading/Loader";
import { useDispatch } from "react-redux";
import { getClientDetail } from "../../Redux/Actions";
import { useNavigate, useParams } from "react-router-dom";

const userdtails = [
  {
    label: "Profile",
  },
  {
    label: "Goals",
  },
  {
    label: "Smart Plans",
  },
  {
    label: "Payment History",
  },
];

const CustomerDetails = () => {
  let Once = true;
  const params = useParams();
  const [activeTab, setActiveTab] = useState("Profile");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (Once) {
      Once = false;
      setLoading(true);
      dispatch(getClientDetail(params?.userId))
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          navigate("/page-not-found");
        });
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bg-white mt-3">
            <ul className="flex  items-center  pr-2  px-2 py-1 ">
              {userdtails.map((elem, index) => (
                <li
                  onClick={() => setActiveTab(elem.label)}
                  className={`${
                    activeTab === elem.label ? "bg-[--primary] text-white " : ""
                  } hover:bg-[--primary] hover:text-white py-2 mr-1 rounded-md cursor-pointer px-3 satoshi-700 transition-all duration-300 flex-grow text-center`}
                  key={index}
                >
                  {elem.label}
                </li>
              ))}
            </ul>
          </div>
          <div>
            {activeTab === "Profile" && <ClientInfo />}
            {activeTab === "Payment History" && <PaymentHistory />}
            {activeTab === "Goals" && <Goals />}
            {activeTab === "Smart Plans" && <SmartPlan />}
          </div>
        </>
      )}
    </>
  );
};

export default CustomerDetails;
