import React from "react";

const ButtonSmall = ({ type, onClick, text, customClasses }) => {
  return (
    <button
      type={type ? type : "submit"}
      onClick={onClick}
      className={`bg-[--primary] text-[11px] sm:text-[13px]  md:text-[16px] text-white  border border-[--primary] satoshi-700 rounded-md py-2 px-8 sm:px-16 ${customClasses} `}
    >
      {text}
    </button>
  );
};

export default ButtonSmall;
