import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import parse from "html-react-parser";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateGoalStepsReopen } from "../../Redux/Actions";

const ReopenGoalModal = ({ open, handleClose, data, customerTab = false }) => {
  const [btnLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();

  const initialState = {
    goalType: data?.goalType,
    goalId: data?._id,
    name: data?.title,
    amount: data?.goalType === "Financial" ? data?.amount : 0,
    notes: data?.note && parse(data?.note),
    steps: data?.steps?.map((item) => {
      return {
        ...item,
        step: {
          ...item.step,
          description: item?.step?.description
            ? parse(item?.step?.description)
            : "",
        },
      };
    }),
    savedAmount: data?.savedAmount || 0,
    clientId: data?.clientId?._id[0] || data?.clientId?._id,
  };

  const { values, setValues, handleSubmit } = useFormik({
    initialValues: initialState,
    onSubmit: (values) => {
      setButtonLoading(true);
      dispatch(updateGoalStepsReopen(values)).then(() => {
        if (customerTab) {
          window.location.reload();
        } else {
          setButtonLoading(false);
          handleClose();
        }
      });
    },
  });

  function handleStepCheck(id) {
    const updatedSteps = values.steps.map((step) => {
      if (step._id === id) {
        return { ...step, status: !step.status }; // Set the status of the clicked step to true
      }
      return step;
    });

    setValues({
      ...values,
      steps: updatedSteps, // Update the steps array in the form values
    });
  }

  return (
    <Modal open={open} onclick={handleClose} headerText={"Reopen Goal"}>
      <p>Please uncheck any of the below step inorder to reopen a goal.</p>

      <div>
        {values.steps.map((step, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                handleStepCheck(step._id);
              }}
              className="flex cursor-pointer hover:bg-slate-200 w-full justify-start items-start gap-3 bg-slate-50 border rounded-md px-3 py-2 my-2"
            >
              <input
                checked={step.status}
                onChange={() => {
                  handleStepCheck(step._id);
                }}
                type="checkbox"
                className="w-[18px] h-[18px] mt-1"
              />
              <div className="flex justify-start items-start flex-col">
                <div className="mb-2">
                  <h3 className="satoshi-700">{step?.step?.title}</h3>
                </div>
                <div className="text-sm satoshi-500 description">
                  {parse(step?.step?.description)}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex justify-center items-center gap-4 mt-6 w-full">
        {btnLoading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              text="Cancel"
              onClick={handleClose}
              customClasses="!bg-white !text-black"
            />
            <ButtonSmall onClick={handleSubmit} text="Reopen" />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ReopenGoalModal;
