import React from "react";
import HeaderData from "../Header/HeaderData";
import { useSelector } from "react-redux";

const PaymentHistory = () => {
  const { data } = useSelector((state) => state.customerDetail);

  return (
    <HeaderData text="Customer Payment History" customClassess={"!my-3"} />
  );
};

export default PaymentHistory;
