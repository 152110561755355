import React, { useState } from "react";
import Modal from "./Modal";
import InputWrapper from "../Wrapper/InputWrapper";
import InputSmall from "../Input/InputSmall";
import ButtonIcon from "../Buttons/ButtonIcon";
import { IoIosAdd } from "react-icons/io";
import ButtonSmall from "../Buttons/ButtonSmall";
import ButtonLoading from "../Buttons/ButtonLoading";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch } from "react-redux";
import { addGoalSteps } from "../../Redux/Actions";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const StepsModal = ({ open, handleClose, data }) => {
  const [step, setStep] = useState("");
  const [btnLoading, setButtonLoading] = useState(false);
  const [note, setNote] = useState(parse(data?.note) || "");
  const [formData, setFormData] = useState({
    goalType: data.goalType,
    goalId: data?._id,
    steps: data?.steps?.map((item) => {
      return {
        ...item,
        step: parse(item.step),
      };
    }),
    note: "",
    staff: data?.staff,
  });

  const dispatch = useDispatch();

  function handleAddStep(e) {
    e.preventDefault();
    setFormData((pre) => {
      return {
        ...pre,
        steps: [...pre.steps, { step: step, status: false }],
      };
    });
    setStep("");
  }

  function handleDeleteStep(index) {
    const steps = formData.steps.filter((_, i) => i !== index);
    setFormData((pre) => {
      return {
        ...pre,
        steps: steps,
      };
    });
  }

  function handleSubmit() {
    formData.note = note;
    setButtonLoading(true);
    dispatch(addGoalSteps(formData)).then(() => {
      setButtonLoading(false);
      handleClose();
    });
  }

  return (
    <Modal open={open} headerText="Assign steps" onclick={handleClose}>
      <div className="w-full flex justify-start items-start flex-col ">
        <div className="w-full">
          <label
            htmlFor="Category"
            className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
          >
            Goal Type
          </label>
          <select
            className="w-full rounded-md  border outline-none py-2 px-2"
            name="goalType"
            id="programType"
            disabled
            value={formData.goalType}
          >
            <option className="text-black" value="Financial">
              Financial
            </option>
            <option className="text-black" value="Nonfinancial">
              Non Financial
            </option>
          </select>
        </div>
      </div>

      <InputWrapper>
        <InputSmall disable={true} value={data.name} label="Goal Title" />
      </InputWrapper>
      {data?.goalType === "Financial" && (
        <>
          <InputWrapper>
            <InputSmall
              disable={true}
              value={data.amount}
              label="Goal Amount"
            />
          </InputWrapper>
          <InputWrapper>
            <InputSmall
              disable={true}
              value={data?.savedAmount}
              label="Saved Amount"
            />
          </InputWrapper>
        </>
      )}
      <InputWrapper>
        <div className="w-full">
          <label
            htmlFor="description"
            className="block pl-[2px] font-medium text-[14px] capitalize"
          >
            Description
          </label>
          <ReactQuill
            theme="snow"
            id="description"
            value={note}
            className="w-full py-3 rounded-md"
            onChange={setNote}
            placeholder="Write description here ..."
          />
        </div>
        {/* <div className="flex-grow basis-48">
          <label
            htmlFor="note"
            className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
          >
            Note
          </label>
          <textarea
            value={formData.note}
            placeholder="Please write a note ..."
            onChange={(e) => {
              setFormData((pre) => {
                return { ...pre, note: e.target.value };
              });
            }}
            className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-md focus:border-gray-800 block w-full px-2.5 py-2"
            id="note"
            name="note"
            cols="30"
            rows="3"
          />
        </div> */}
      </InputWrapper>
      {formData?.steps?.length ? (
        <div className="py-3">
          {formData.steps.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-center items-start gap-3"
              >
                <div
                  key={index}
                  className="flex w-full justify-start items-center gap-3 bg-slate-50 border rounded-md px-3 py-1 my-2"
                >
                  <input disabled type="checkbox" />
                  <div className="text-sm satoshi-500 description">
                    {parse(item.step)}
                  </div>
                </div>
                <button className="flex justify-center my-2 px-2 py-1  items-center text-red-700 border border-red-700 rounded-sm transition-all ease-in-out duration-500 hover:bg-red-700 hover:text-white">
                  <MdDeleteForever
                    onClick={() => handleDeleteStep(index)}
                    className="text-xl"
                  />
                </button>
              </div>
            );
          })}
        </div>
      ) : null}

      {formData?.goalType === "Nonfinancial" && (
        <div className="w-full">
          <InputWrapper>
            <div className="w-full">
              <label
                htmlFor="step"
                className="block pl-[2px] font-medium text-[14px] capitalize"
              >
                Step
              </label>
              <ReactQuill
                theme="snow"
                id="description"
                value={step}
                className="w-full py-1 rounded-md"
                onChange={setStep}
                placeholder="Write Step here ..."
              />
            </div>
          </InputWrapper>
          <div className="flex justify-center items-center my-3">
            <ButtonIcon
              onclick={handleAddStep}
              type="submit"
              customClasses="!py-2 !px-3"
              text="Add Step"
              icon={<IoIosAdd />}
            />
          </div>
        </div>
      )}

      <div className="flex justify-center items-center gap-4 mt-6 w-full">
        {btnLoading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              onClick={handleClose}
              customClasses="!bg-white !text-black"
            />
            <ButtonSmall
              type="button"
              text={"Save & Exit"}
              onClick={handleSubmit}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default StepsModal;
