import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import TableHeader from "../../components/Table/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getTaskData } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import moment from "moment";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import UpdateTaskStatus from "../../components/Modal/updateTaskStatus";
import TaskHistoryModal from "../../components/Modal/TaskHistoryModal";

let Once = true;

const Task = () => {
  const [activeTab, setActiveTab] = useState("todo");

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const taskData = useSelector((state) => state.tasks.data);
  const { todo, inProgress, completed } = useSelector((state) => state.tasks);
  const [modal, setModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [detail, setDetail] = useState({});

  const [data, setData] = useState({
    staffId: null,
    taskId: null,
    status: null,
    id: null,
  });


  function handleCloseModal() {
    setData({
      staffId: null,
      taskId: null,
      status: null,
    });
    setModal(false);
  }

  function handleChange(e, taskId, mainId) {
    setData({
      staffId: null,
      taskId: taskId,
      status: e.target.value,
      id: mainId,
    });
    setModal(true);
  }

  function handleChange2(e, taskId, mainId, status) {
    setDetailModal(false);
    setData({
      staffId: mainId,
      taskId: taskId,
      status: activeTab,
      id: mainId,
    });
    setModal(true);
    // setDetailModal(true)

  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(taskData)) {
        setLoading(true);
        dispatch(getTaskData()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {modal ? (
            <UpdateTaskStatus
              open={modal}
              handleClose={handleCloseModal}
              data={data}
            />
          ) : null}

          {detailModal ? (
            <TaskHistoryModal
              open={detailModal}
              handleClose={() => {
                setDetailModal(false);
              }}
              status={activeTab}
              data={detail}
              onClick={() => {
                setDetailModal(false);
                setData({
                  staffId: detail?._id,
                  taskId: detail?.taskDetails?._id,
                  status: detail?.curentStatus,
                  id: detail?._id,
                });
                setModal(true);
              }}

            />
          ) : null}

          <HeaderData text="Tasks">
            {/* {taskData?.length ? (
              <ButtonIcon
                icon={<IoMdAdd />}
                text="Make a reschedule request to coach"
              />
            ) : null} */}
          </HeaderData>
          {taskData.length ? (
            <>
              <div className="bg-white rounded-md mt-3">
                <div className="px-3 w-full">
                  <TableHeader text="Task Assigned" />
                </div>
                <div className="w-full list-none flex justify-start items-center gap-2 sm:gap-5 border-b">
                  <li
                    onClick={() => {
                      setActiveTab("todo");
                    }}
                    className={`tabs py-3 px-1 md:px-4 cursor-pointer satoshi-700 text-[12px] md:text-[14px] ${activeTab === "todo" ? "activeTab" : ""
                      }`}
                  >
                    To Do ({todo})
                  </li>

                  <li
                    onClick={() => {
                      setActiveTab("inprogress");
                    }}
                    className={`tabs py-3 px-1 md:px-4 cursor-pointer satoshi-700 text-[12px] md:text-[14px] ${activeTab === "inprogress" ? "activeTab" : ""
                      }`}
                  >
                    In Progress ({inProgress})
                  </li>

                  <li
                    onClick={() => {
                      setActiveTab("completed");
                    }}
                    className={`tabs py-3 px-1 md:px-4 cursor-pointer satoshi-700 text-[12px] md:text-[14px] ${activeTab === "completed" ? "activeTab" : ""
                      }`}
                  >
                    Completed ({completed})
                  </li>
                </div>
              </div>
              <div className="dashboardTableWrapper bg-white">
                <table className="bg-white w-full min-w-[700px] border-collapse !overflow-x-auto rounded-md ">
                  <thead>
                    <tr>
                      <th className="px-3 py-2 text-[--gray] satoshi-500 text-left text-sm ">
                        Description
                      </th>
                      <th className="pr-2 pl-8 py-2 text-[--gray] satoshi-500 text-sm text-left">
                        Start Date
                      </th>
                      <th className="px-2 py-2 text-[--gray] satoshi-500 text-sm text-left">
                        End Date
                      </th>
                      <th className="px-2 py-2 text-[--gray] satoshi-500 text-sm text-left">
                        Detail
                      </th>
                      <th className="px-2 py-2 text-[--gray] satoshi-500 text-left">
                        Status
                      </th>
                    </tr>
                    {/* <th className="px-2 py-2 text-[--gray] satoshi-500 text-sm text-left">
              Tag
            </th> */}
                  </thead>
                  <tbody>
                    {taskData
                      ?.filter((task) => {
                        return (
                          task.curentStatus.toLowerCase() ===
                          activeTab.toLocaleLowerCase()
                        );
                      })
                      .map((item, index) => {
                        return (
                          <tr key={index} className="border-t">
                            <td
                              onClick={(e) => {
                                e.target.classList.toggle("whitespace-nowrap");
                              }}
                              className=" px-3 py-2  satoshi-500 text-sm pr-8 max-w-[180px] whitespace-nowrap text-ellipsis "
                            >
                              {item?.taskDetails?.title}
                            </td>
                            <td className=" pr-2 pl-8 py-2 satoshi-500 text-sm">
                              {moment(
                                parseInt(item.taskDetails.createdAt)
                              ).format("YYYY-MM-DD")}
                            </td>
                            <td className=" px-2 py-2 satoshi-500 text-sm ">
                              {moment(item.endDate).format("YYYY-MM-DD")}
                            </td>
                            <td
                              onClick={() => {
                                setDetail(item);
                                setDetailModal(true);
                              }}
                              className=" px-2 pl-4 py-2 satoshi-500 text-[18px] text-blue-400 cursor-pointer"
                            >
                              View Detail
                            </td>
                            {/* <td className=" px-2 pl-4 py-2 satoshi-500 text-[18px] ">
                              {item.isLate ? (
                                <RxCross2 className="text-red-600 text-center" />
                              ) : (
                                <FaCheck className="text-green-600 text-center" />
                              )}
                            </td> */}
                            {/* <td className="px-2 py-2 text-sm ">
                <div className=" w-max h-full rounded-full border border-[--orange] bg-[--gray-light] text-[--orange]">
                  <p className="px-2 py-1 satoshi-500">Financial Task</p>
                </div>
              </td> */}
                            <td className=" px-2 py-2 satoshi-500 text-xl text-right w-[60px]">
                              <select
                                defaultValue={item.curentStatus}
                                disabled={
                                  item.curentStatus.toLowerCase() ===
                                  "completed"
                                }
                                onChange={(e) => {
                                  handleChange(
                                    e,
                                    item?.taskDetails?._id,
                                    item._id
                                  );
                                }}
                                name="status"
                                id="status"
                                className="border border-black outline-none py-1 pr-1 pl-2 text-[14px] cursor-pointer"
                              >
                                <option
                                  disabled={
                                    item.curentStatus.toLowerCase() ===
                                    "inprogress"
                                  }
                                  value="ToDo"
                                >
                                  ToDo
                                </option>
                                <option value="InProgress">In Progress</option>
                                <option value="Completed">Complete</option>
                              </select>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p>You dont have any task</p>
          )}
        </>
      )}
    </>
  );
};

export default Task;
