import React, { useEffect, useState } from "react";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import Modal from "./Modal";
import InputWrapper from "../Wrapper/InputWrapper";
import InputRequired from "../Input/InputRequired";
import ReactQuill from "react-quill";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import ButtonIcon from "../Buttons/ButtonIcon";
import { IoIosAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData, updateGoal } from "../../Redux/Actions";
import { useFormik } from "formik";
import { updateGoalSchema } from "../../Schema";
import Loader from "../Loading/Loader";
import parse from "html-react-parser";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const labelClassess = "satoshi-500 !mt-3 !text-black !text-[14px]";

let Once = true;

const UpdateAssignGoal = ({ open, handleClose, data, customerTab = false }) => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboard);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState(data?.note ? parse(data?.note) : null);
  const [stepTitle, setStepTitle] = useState("");
  const [stepDescription, setStepDescription] = useState("");
  const [allStaff, setAllStaff] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [isStepUpdate, setStepUpdate] = useState({
    status: false,
    targetIndex: 0,
  });

  const initialState = {
    goalId: data?._id,
    goalType: data?.goalType || "",
    amount: data?.amount || 0,
    name: data?.name || "",
    duration: data?.duration || 1,
    durationType: data?.durationType || "month",
    programs: data?.program || [],
    steps: data?.steps?.map((item) => {
      return {
        ...item,
        step: {
          ...item.step,
          description: item?.step?.description && parse(item?.step?.description),
        },
      };
    }),
    note: null,
    staff: data?.staff || [],
    clientId: customerTab ? data?.clientId?._id : data?.clientId?._id[0],
  };

  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialState,
    validationSchema: updateGoalSchema,
    onSubmit: (values) => {
      values.note = note;
      setBtnLoading(true);
      console.log(values)
      dispatch(updateGoal(values)).then(() => {
        if (customerTab) {
          window.location.reload();
        } else {
          setBtnLoading(false);
          handleClose();
        }
      });
    },
  });

  function handleSelectAllStaff(e) {
    if (e.target.checked) {
      setValues((pre) => ({
        ...pre,
        staff: dashboardData?.staff?.map((item) => item._id),
      }));
      setAllStaff(true);
    } else {
      setValues((pre) => ({ ...pre, staff: [] }));
      setAllStaff(false);
    }
  }

  function handleDeleteStep(stpIndex) {
    setValues((prevValues) => ({
      ...prevValues,
      steps: prevValues.steps.filter((_, index) => index !== stpIndex),
    }));
    // Reset dropdown state for deleted step
    const newDropdownStates = { ...dropdownStates };
    delete newDropdownStates[stpIndex];
    setDropdownStates(newDropdownStates);
  }

  function handleAddStep(e) {
    e.preventDefault();
    e.stopPropagation();

    if (isStepUpdate.status) {
      const updatedStep = values.steps.map((item, index) => {
        if (index === isStepUpdate.targetIndex) {
          return {
            ...item,
            step: {
              title: stepTitle,
              description: stepDescription,
            },
          };
        }
        return item;
      });
      setValues((prevValues) => ({ ...prevValues, steps: updatedStep }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        steps: [
          ...prevValues.steps,
          {
            step: { title: stepTitle, description: stepDescription },
            status: false,
          },
        ],
      }));
    }

    setStepTitle("");
    setStepDescription("");
    setStepUpdate({ status: false, targetIndex: 0 });
  }

  function handleSelectUpdateStep(stepIndex) {
    const targetValue = values.steps[stepIndex];
    setStepTitle(targetValue.step.title);
    setStepDescription(targetValue.step.description);
    setStepUpdate({ status: true, targetIndex: stepIndex });
  }

  function toggleDropdown(stepIndex) {
    setDropdownStates((prev) => ({
      ...prev,
      [stepIndex]: !prev[stepIndex],
    }));
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      setLoading(true);
      if (dashboardData.isNotInvoked) {
        dispatch(getDashboardData()).finally(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, []);

  return (
    <Modal headerText="Update Goal" open={open} onclick={handleClose}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="w-full flex flex-col">
            {/* Goal Type Section */}
            <div className="w-full">
              <label htmlFor="goalType" className="block mb-1 pl-[2px] font-medium text-[14px] capitalize">
                Goal Type
              </label>
              <select
                className="w-full rounded-md border outline-none py-2 px-2"
                name="goalType"
                id="goalType"
                onBlur={handleBlur}
                value={values.goalType}
                onChange={(e) => {
                  setValues((pre) => ({
                    ...pre,
                    amount: 0,
                    steps: [],
                    [e.target.name]: e.target.value,
                  }));
                }}
              >
                <option value="">Select Goal Type</option>
                <option value="Financial">Financial</option>
                <option value="Nonfinancial">Non Financial</option>
              </select>
            </div>
            {touched.goalType && errors.goalType && (
              <p className="text-red-600 text-[14px] mt-1 text-center">
                {errors.goalType}
              </p>
            )}
            {/* Title Input */}
            <InputRequired
              name="name"
              label="Title"
              placeholder="Goal Title"
              type="text"
              labelStyle={labelClassess}
              value={values.name}
              touch={touched.name}
              error={errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {/* Amount Input for Financial Goals */}
            {values.goalType === "Financial" && (
              <InputRequired
                name="amount"
                label="Goal Amount"
                placeholder="Goal Amount"
                type="number"
                labelStyle={labelClassess}
                value={values.amount}
                onChange={handleChange}
              />
            )}
            {/* Duration Section */}
            <div className="flex flex-col mt-3">
              <label htmlFor="duration" className={`mb-1 pl-[2px] capitalize ${labelClassess}`}>
                Duration
              </label>
              <div className="flex items-center">
                <input
                  name="duration"
                  value={values.duration}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border w-[20%] border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg block p-2"
                  type="number"
                  placeholder="Duration Value"
                />
                <select
                  className="ml-3 border w-[20%] border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg block p-2"
                  name="durationType"
                  value={values.durationType}
                  onChange={handleChange}
                >
                  <option value="days">Days</option>
                  <option value="weeks">Weeks</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                </select>
              </div>
              {touched.duration && errors.duration && (
                <p className="text-red-600 text-[14px] mt-1 text-center">
                  {errors.duration}
                </p>
              )}
            </div>
            {/* Note Section */}
            <InputWrapper>
              <div className="w-full">
                <label htmlFor="note" className="block pl-[2px] font-medium text-[14px] capitalize">
                  Note
                </label>
                <ReactQuill
                  theme="snow"
                  id="note"
                  value={note}
                  className="w-full py-1 rounded-md"
                  onChange={setNote}
                  placeholder="Write note here ..."
                />
              </div>
            </InputWrapper>
            {/* Steps Section */}
            {values.steps.length > 0 && (
              <div className="py-3">
                {values.steps.map((item, index) => {
                  const isDropdownOpen = dropdownStates[index] || false;
                  return (
                    <div key={index} className="flex justify-center items-start gap-3">
                      <div className="flex w-full justify-start items-start gap-3 bg-slate-50 border rounded-md px-3 py-1 my-2">
                        <input
                          type="checkbox"
                          className="min-w-[15px] min-h-[15px] mt-1"
                          checked={item.status}
                          onChange={() => {
                            setValues((prevValues) => {
                              const updatedSteps = [...prevValues.steps];
                              updatedSteps[index].status = !item.status;
                              return { ...prevValues, steps: updatedSteps };
                            });
                          }}
                        />
                        <div>
                          <div 
                            onClick={() => toggleDropdown(index)}
                            className="mb-3 flex justify-between pr-5 items-center cursor-pointer w-full gap-10"
                          >
                            <h3 className="border-b border-black satoshi-700">
                              {item.step.title}
                            </h3>
                            {isDropdownOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                          </div>
                          {isDropdownOpen && (
                            <div className="text-sm satoshi-500 description">
                              {parse(item.step.description)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-start items-start flex-col pr-1 min-w-max">
                        <MdDeleteForever
                          onClick={() => handleDeleteStep(index)}
                          className="text-2xl mt-2 text-red-700 cursor-pointer hover:text-red-400"
                        />
                        <FaEdit
                          className="text-xl text-green-600 mt-3 cursor-pointer hover:text-green-400"
                          onClick={() => handleSelectUpdateStep(index)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* Add Step Section for Non-financial Goals */}
            {values.goalType === "Nonfinancial" && (
              <form onSubmit={handleAddStep}>
                <InputWrapper>
                  <InputRequired
                    name="stepTitle"
                    value={stepTitle}
                    label="Step Title"
                    placeholder="Enter step title"
                    onChange={(e) => setStepTitle(e.target.value)}
                    required={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <div className="w-full">
                    <label htmlFor="step" className="block pl-[2px] font-medium text-[14px] capitalize">
                      Step
                    </label>
                    <ReactQuill
                      theme="snow"
                      id="step"
                      value={stepDescription}
                      className="w-full py-1 rounded-md"
                      onChange={setStepDescription}
                      placeholder="Write step description here ..."
                    />
                  </div>
                </InputWrapper>
                <div className="flex justify-center items-center my-3">
                  <ButtonIcon
                    type="submit"
                    customClasses="!py-2 !px-3"
                    text="Add Step"
                    icon={<IoIosAdd />}
                  />
                </div>
              </form>
            )}
            {/* Update and Cancel Buttons */}
            <div className="w-full flex justify-center items-center gap-5 mt-3">
              {btnLoading ? (
                <ButtonLoading />
              ) : (
                <>
                  <ButtonSmall
                    text="Cancel"
                    onClick={handleClose}
                    customClasses={"!bg-white !text-[--primary]"}
                  />
                  <ButtonSmall text="Update" onClick={handleSubmit} />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default UpdateAssignGoal;
