import React from "react";
import Avatar from "../Avatar/Avatar";
import moment from "moment";
import { Link } from "react-router-dom";

const ClientRow = ({ data }) => {
  return (
    <tr className="text-left border-t">
      <td>
        <Link
          to={`/clients-detail/${data?._id}`}
          className="flex justify-start items-center py-2 px-2 pl-3 min-w-max"
        >
          <Avatar image={data?.photo} />
          <div className=" ml-2 flex justify-center items-center flex-col w-max">
            <h5 className="text-[12px] sm:text-[14px] lg:text-[16px] capitalize">
              {data.firstName + " " + data.lastName}
            </h5>
            {/* <p className="text-[14px] text-[--gray] "></p> */}
          </div>
        </Link>
      </td>
      <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px]">
        {data?.email}
      </td>
      <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] text-center">
        {data?.totalPrograms}
      </td>
      <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] min-w-max">
        {moment(parseInt(data?.joinedAt)).format("DD-MM-YYYY")}
      </td>
      {/* <td className="px-3">
        <div className="w-max px-2 py-1 satoshi-500 rounded-md bg-black text-white">
          <p className="px-2 text-[11px] sm:text-[13px] md:text-[15px]">
            Schedule a meeting
          </p>
        </div>
      </td> */}
      <td className="px-2 text-[11px] capitalize sm:text-[13px] md:text-[15px] lg:text-[16px] pl-4 cursor-default">
        {data?.packageInfo?.packageName || "no package"}
      </td>
    </tr>
  );
};

export default ClientRow;
