import React from "react";
import Modal from "./Modal";
import moment from "moment";
import ButtonSmall from "../Buttons/ButtonSmall";
import parse from "html-react-parser";

const TaskHistoryModal = ({ open, handleClose, data, status, onClick }) => {
  return (
    <Modal open={open} onclick={handleClose} headerText="Task Detail">
      <div className="px-5">
        <h2 className="satoshi-700">Task Title</h2>
        <p>{data?.taskDetails?.title}</p>

        <h2 className="satoshi-700">Coach Info</h2>
        <p>
          {data?.taskDetails?.firstName + " " + data?.taskDetails?.lastName}
        </p>

        <h3 className="satoshi-700 mt-2">Task Description</h3>
        <p className="description">
          {parse(parse(data?.taskDetails?.description))}
        </p>
      </div>
      <div className="mx-auto px-5 py-3 flex justify-between items-stretch w-full gap-4 flex-wrap">
        {data.status.length
          ? data.status.map((item) => {
            return (
              <div className="flex-grow basis-72 rounded-md singleTask px-2 py-3">
                <div className="w-full flex justify-start items-center">
                  <p className="satoshi-700 w-[45px]">Status</p>
                  <span className="px-1 satoshi-700">:</span>
                  <p className="capitalize">
                    {item.taskStatus.toLowerCase()}
                  </p>
                </div>
                <div className="w-full flex justify-start items-center mt-2">
                  <p className="satoshi-700 w-[45px]">Date</p>
                  <span className="px-1 satoshi-700">:</span>
                  <p>{moment(parseInt(item.date)).format("DD MMM, YYYY")}</p>
                </div>
                {item.note ? (
                  <div className="w-full flex justify-start items-center">
                    <p className="satoshi-700 w-[45px]">Note</p>
                    <span className="px-1 satoshi-700">:</span>
                    <p className="capitalize">{item.note}</p>
                  </div>
                ) : null}
              </div>
            );
          })
          : null}
      </div>
      <div className="flex justify-center items-center gap-4 mt-6 w-full">
        {
          status === "inprogress" && (
            <ButtonSmall
              type="button"
              text="Add Note"
              onClick={onClick}
              customClasses="!bg-primary"
            />
          )
        }
        <ButtonSmall
          type="button"
          text="Cancel"
          onClick={handleClose}
          customClasses="!bg-primary"
        />
      </div>
    </Modal>
  );
};

export default TaskHistoryModal;
