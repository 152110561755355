import React, { useEffect, useState } from 'react';
import Form from '../Form/Form';
import { useFormik } from 'formik';
import InputRequired from '../Input/InputRequired';
import ButtonLoading from '../Buttons/ButtonLoading';
import ButtonLarge from '../Buttons/ButtonLarge';
import { otpSchema } from '../../Schema';
import { emailVerification, verifyOtp } from '../../Redux/Actions';

const OTPduration = 60;

const OtpStep = ({onNext,onBack,setStepValues,stepsValues}) => {
    const [loading, setLoading] = useState(false);
    const [resendOTP,setResendOTP] = useState(false);
    const [showResend,setShowResend] = useState(false);
    const [seconds, setSeconds] = useState(OTPduration);
    const [expired,setExpired] = useState(false);
    const initialState={
        code:"",
        inputCode:""
    }

  // Convert seconds to minutes and seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  // Format minutes and seconds with leading zeros
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");


 

  const {values,errors,touched,handleBlur,handleChange,handleSubmit} = useFormik({
    initialValues: initialState,
    validationSchema:otpSchema,
    onSubmit:(values,{resetForm})=>{
      values.code = stepsValues.code;
      setLoading(true);
      verifyOtp(stepsValues.userId,values).then((res)=>{
        localStorage.setItem('st_access', JSON.stringify(res?.token));
        localStorage.setItem('st_refresh', JSON.stringify(res.refreshToken));
        resetForm();
        onNext();
      }).catch((err)=>{
        console.error(err);
        setLoading(false);
        setShowResend(true);
        resetForm();
      })
    
    }
});


const handleResendOTP = () => {
  setLoading(true);
  emailVerification({email:stepsValues.email}).then((res)=>{
    setStepValues({
        email:stepsValues.email,
        userId:res.userid,
        code:res.code
    })
    setLoading(false);
    setShowResend(false);
    
    setExpired(false);
    setResendOTP(false); // Reset resendOTP state
    setSeconds(OTPduration); // Reset timer 
    values.inputCode="" 
    }).catch((err)=>{
        console.error(err);
        setLoading(false);
    })

};


    useEffect(() => {
        const intervalId = setInterval(() => {
          setSeconds((prevSeconds) => {
            if (prevSeconds === 0) {
              clearInterval(intervalId);
              setShowResend(true);
              setResendOTP(true);
              setExpired(true);
              return 0;
            }
            return prevSeconds - 1;
          });
        }, 1000);
    
        return () => clearInterval(intervalId);
      }, [resendOTP]);
    return (
      <Form headerText="OTP Verification" description='We have successfully send OTP to your email address.' onSubmit={handleSubmit}>
         
         <InputRequired
          label="OTP"
          type="number"
          id="otp"
          name="inputCode"
          value={values.inputCode}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.inputCode}
          touch={touched.inputCode}
          placeholder="Please enter OTP here"
        />
        {
            !resendOTP? <div>
              {!expired ? <p className='text-red-600 text-[14px] satoshi-700'>OTP will expired in {formattedMinutes}:{formattedSeconds}</p> :<p className='text-red-600 text-[14px] satoshi-700'>OTP expired</p> }
            
        </div>
        :null
        }
        
          {loading ? <ButtonLoading /> : <>
          {
            showResend ? <ButtonLarge onClick={handleResendOTP} type="button" text="Resend OTP"/>:null
          }
          {
            !expired ? <ButtonLarge onClick={handleSubmit} text="Verify OTP"/> : null
          }
          
          <ButtonLarge type="button" onClick={onBack} text="Back"/>
          </>}

      </Form>
    );
}

export default OtpStep;
