import React from "react";

const GoalTableHead = () => {
  return (
    <thead>
      <tr className="border-b border-black">
        <th className="text-left px-2 pl-4 py-4 text-[--gray] text-sm ">
          Name
        </th>
        <th className="text-left px-2 pl-3 py-4 text-[--gray] text-sm ">
          Title
        </th>
        <th className="text-left px-2 py-4 text-[--gray] text-sm ">Type</th>
        <th className="text-left px-2 py-4 text-[--gray] text-sm ">
          Completed
        </th>
        <th className="text-left px-2 py-4 text-[--gray] text-sm w-[190px]">
          Duration
        </th>
        <th className="text-right px-2 py-4 text-[--gray] text-sm  w-[240px]"></th>
      </tr>
    </thead>
  );
};

export default GoalTableHead;
