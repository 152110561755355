import * as Yup from "yup";

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} `;
};

const stringValidation = (message = "Field is required") =>
  Yup.string().required(message);

export const loginSchema = Yup.object({
  email: stringValidation().email(),
  password: stringValidation(),
});
export const emailSchema = Yup.object({
  email: loginSchema.fields.email,
});

const conditionalNumberValidation = (dependencyKey, value) => {
  return Yup.number().when(dependencyKey, {
    is: (val) => {
      return val === value;
    },
    then: (s) =>
      s
        .required("Field is required")
        .min(1, "Field value must be greater than 0"),
    otherwise: (s) => s.nullable(),
  });
};

export const otpSchema = Yup.object({
  inputCode: Yup.number()
    .typeError("OTP must be a number")
    .min(99999, "Six digit only")
    .max(999999, "Six digit only")
    .required("OTP is required"),
});

export const passwordSchema = Yup.object({
  password: stringValidation("Password is required")
    .min(6, "Password must be at least 6 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase character"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase character")),
  confirmPassword: stringValidation("Confirm password is required").oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const taskSchema = Yup.object({
  note: stringValidation("Note is required"),
});

export const updateProfile = Yup.object({
  firstName: stringValidation("First Name is required"),
  lastName: stringValidation("Last Name is required"),
  email: stringValidation().email("Email is required"),
  staffInfo: Yup.object().shape({
    phone: Yup.number()
      .typeError("Phone number must be numbers, not alphabets")
      .max(9999999999999999, "Number should not be maximum than 17 digits")
      .required("Phone number is required"),
  }),
});

export const eventSchema = Yup.object({
  title: stringValidation("Title is required"),
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date()
    .required("End date is required")
    .min(
      Yup.ref("startDate"),
      "End date must be greater than or equal to start date"
    ),
});

export const updateClientProfile = Yup.object({
  firstName: stringValidation(),
  lastName: stringValidation(),
  email: stringValidation().email(),
  clientInfo: Yup.object().shape({
    coachId: Yup.string(),
    dob: Yup.string(),
    occupation: Yup.string(),
    phone: Yup.string(),
    address: Yup.string(),
  }),
});

export const goalSchema = Yup.object().shape({
  goalType: Yup.string()
    .oneOf(["Financial", "Nonfinancial"])
    .required("Goal Type is required"),
  amount: conditionalNumberValidation("goalType", "Financial"),
  name: stringValidation("Goal title is required"),
  duration: Yup.number()
    .positive("Duration must be greater than zero")
    .required("Duration must be greater than zero"),
  durationType: Yup.string().required(),
  programs: Yup.array().nullable(),
  steps: Yup.array().nullable(),
  note: Yup.string().nullable(),
  staff: Yup.array().nullable(),
});

export const updateGoalSchema = Yup.object().shape({
  goalType: Yup.string()
    .oneOf(["Financial", "Nonfinancial"])
    .required("Goal Type is required"),
  amount: Yup.number().nullable(),
  name: stringValidation("Goal title is required"),
  duration: Yup.number()
    .positive("Duration must be greater than zero")
    .required("Duration must be greater than zero"),
  durationType: Yup.string().required(),
  programs: Yup.array(),
  steps: Yup.array().nullable(),
  note: Yup.string().nullable(),
  staff: Yup.array().nullable(),
});

export const updatePasswordSchema = Yup.object({
  currentPassword: stringValidation("Please enter your current password"),
  password: passwordSchema.fields.password,
  confirmPassword: passwordSchema.fields.confirmPassword,
});

export const updateEmailSchema = Yup.object({
  newEmail: stringValidation('Email is required').email(),
})
