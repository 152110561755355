import React, { useState } from "react";
// import { Calendar, momentLocalizer } from "react-big-calendar";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddEventModal from "../Modal/AddEventModal";
import { useSelector } from "react-redux";

const localizer = momentLocalizer(moment);
// Tue Apr 02 2024 00:00:00 GMT+0500 (Pakistan Standard Time)
const MyCalender = () => {
  const [modal, setModal] = useState(false);
  const [newEvent, setNewEvent] = useState({});
  const events = useSelector((state) => state.events.data);
  const [isEdit, setIsEdit] = useState(false);

  const handleSelect = ({ start, end }) => {
    setNewEvent({
      startDate: start,
      endDate: end,
      title: "",
      clients: [],
      staffs: [],
      isUpdate: false,
      createdBy: "Staff",
      coachId: null,
    });
    setModal(true);
  };

  const updateMeeting = (eventData) => {
    setIsEdit(true);
    setNewEvent({
      _id: eventData._id,
      startDate: eventData.start,
      endDate: eventData.end,
      title: eventData.title,
      clients: eventData.clients,
      staffs: [],
      isUpdate: true,
      createdBy: eventData.createdBy,
      coachId: eventData.coachId,
    });
    setModal(true);
  };

  const eventPropGetter = (event) => {
    let backgroundColor = event.createdBy === "Staff" ? "#90662C" : "#296B31"; // Default color
    return { style: { backgroundColor } };
  };

  return (
    <>
      <Calendar
        views={["month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        style={{ height: "600px" }}
        onSelectEvent={updateMeeting}
        onSelectSlot={handleSelect}
        popup
        eventPropGetter={eventPropGetter}
      />
      {modal && (
        <AddEventModal
          open={modal}
          data={newEvent}
          isEdit={isEdit}
          handleClose={() => {
            setIsEdit(false);
            setModal(false);
          }}
        />
      )}
    </>
  );
};

export default MyCalender;
