import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import Avatar from "../../components/Avatar/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Loader from "../../components/Loading/Loader";
import Message from "../../components/Message/Message";
import { getListedChat, readMessage, sendMessage } from "../../Redux/Actions";
import { GrSend } from "react-icons/gr";
import { useSocket } from "../../context/socketContext";
import DeleteMessage from "../../components/Modal/DeleteMessage";

const Messages = () => {
  let Once = true;
  const messagesContainerRef = useRef(null);
  const navigate = useNavigate();
  const socket = useSocket();
  const dispatch = useDispatch();
  const { to } = useParams();
  const [loading, setLoading] = useState(true);
  const staffData = useSelector((state) => state.auth.userData);
  const chatListing = useSelector((state) => state.chatListing.data);
  const allUser = useSelector((state) => state.allChatUser.data);
  const [isMessageSend, setIsMessageSend] = useState(false);
  const [selectedChat, setSelectedChat] = useState({
    status: false,
    userData: null,
  });

  const [newMessageData, setNewMessagedata] = useState({
    message: "",
    createdAt: new Date(),
    read: true,
    direction: {
      type: "right",
      user: {
        _id: staffData?._id,
        firstName: staffData?.firstName,
        lastName: staffData?.lastName,
        photo: staffData?.photo,
      },
    },
  });

  const [deleteMessage, setDeleteMessage] = useState({
    modal: false,
    data: null,
  });

  function removeMessage(data) {
    dispatch({ type: "REMOVE_MESSAGE", payload: data });
    if (
      selectedChat?.status &&
      selectedChat?.userData?._id?.sender?._id === data?._id?.sender?._id
    ) {
      setSelectedChat((pre) => {
        return {
          ...pre,
          userData: {
            ...pre.userData,
            messages: pre.userData.messages.filter(
              (message) => message._id !== data.message
            ),
          },
        };
      });
    }
  }
  const handleDeleteMessage = (messageId) => {
    setSelectedChat((prev) => ({
      ...prev,
      userData: {
        ...prev.userData,
        messages: prev.userData.messages.filter(
          (message) => message._id !== messageId
        ),
      },
    }));
  };

  function scrollToBottom() {
    console.log();
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }

  function handleSendMessage(e) {
    e.preventDefault();
    if (!newMessageData?.message) {
      return;
    } else {
      setIsMessageSend(true);
      const body = {
        sender: staffData._id,
        receiver: selectedChat?.userData?._id?.sender?._id,
        message: newMessageData.message,
      };
      setSelectedChat((pre) => {
        return {
          ...pre,
          userData: {
            ...pre.userData,
            messages: [
              ...pre.userData.messages,
              {
                isSending: true,
                ...newMessageData,
              },
            ],
          },
        };
      });
      setNewMessagedata((pre) => {
        return {
          ...pre,
          message: "",
        };
      });

      scrollToBottom();
      dispatch(sendMessage(body))
        .then((res) => {
          setSelectedChat((pre) => {
            return {
              ...pre,
              userData: {
                ...pre.userData,
                messages: pre.userData.messages.map((message) => {
                  if (message.isSending) {
                    return {
                      ...message,
                      _id: res.data.data._id,
                      isSending: false,
                    };
                  } else {
                    return message;
                  }
                }),
              },
            };
          });
        })
        .catch((err) => {
          setSelectedChat((pre) => {
            return {
              ...pre,
              userData: {
                ...pre.userData,
                messages: pre.userData.messages.filter(
                  (message) => !message.isSending
                ),
              },
            };
          });
        });
    }
  }

  const getTargetChat = () => {
    setLoading(true);
    let targetChat = chatListing.find((chat) => chat._id.sender._id === to);
    if (!targetChat) {
      const targetUser = allUser.find((user) => user._id === to);
      targetChat = {
        _id: {
          sender: {
            firstName: targetUser?.firstName,
            lastName: targetUser?.lastName,
            photo: targetUser?.photo,
            _id: targetUser?._id,
          },
        },
        unread: 0,
        messages: [],
      };
    }
    setSelectedChat({
      status: true,
      userData: targetChat,
    });
    setLoading(false);
  };

  useEffect(() => {
    const handleMessage = (data) => {
      if (chatListing.length) {
        const targetChat = chatListing.find(
          (chat) => chat._id.sender._id === data?._id?.sender?._id
        );
        if (!targetChat) {
          dispatch({ type: "ADD_NEW_CHAT", payload: data });
        } else {
          if (
            selectedChat?.status &&
            selectedChat?.userData?._id?.sender?._id === data?._id?.sender?._id
          ) {
            // Update the selected chat messages if the incoming message belongs to the currently selected chat
            setSelectedChat((prev) => ({
              ...prev,
              userData: {
                ...prev.userData,
                messages: [...prev.userData.messages, data.messages[0]],
              },
            }));
            readMessage({
              sender: selectedChat?.userData?._id?.sender?._id,
              receiver: staffData?._id,
            });
            dispatch({ type: "NEW_MESSAGE_READ", payload: data });
          } else {
            // If the message does not belong to the selected chat, just update the chat listing
            dispatch(getListedChat(staffData._id));
          }
        }
      } else {
        dispatch({ type: "ADD_NEW_CHAT", payload: data });
      }
    };

    scrollToBottom();

    socket.on("message", handleMessage);
    socket.on("messageDeleted", removeMessage);
    return () => {
      socket.off("message", handleMessage);
      socket.off("messageDeleted", removeMessage);
    };
  }, [chatListing, selectedChat]);

  useEffect(() => {
    scrollToBottom();
    if (!isMessageSend) {
      if (selectedChat?.userData?.unread > 0) {
        readMessage({
          sender: selectedChat?.userData?._id?.sender?._id,
          receiver: staffData?._id,
        }).then(() => {
          dispatch(getListedChat());
        });
      }
    }
  }, [selectedChat]);

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(allUser)) {
        navigate("/inbox");
      } else {
        getTargetChat();
      }
    }
  }, []);

  return (
    <>
      {deleteMessage.modal && (
        <DeleteMessage
          data={deleteMessage.data}
          open={deleteMessage.modal}
          handleModal={() => {
            setDeleteMessage({ modal: false, data: null });
          }}
          handleLocalDelete={handleDeleteMessage}
        />
      )}
      <div className=" w-full bg-white rounded-lg">
        <div className="w-full flex justify-between items-center border-b">
          <div className="flex justify-between items-center py-2">
            <Link
              to="/inbox"
              className="pl-3 pr-5 flex justify-start items-center py-2"
            >
              <GoArrowLeft className="text-xl satoshi-600" />
            </Link>
            <Avatar image={selectedChat?.userData?._id?.sender?.photo} />
            <div className=" ml-2 flex justify-center items-center flex-col">
              <h5 className="satoshi-700 text-[12px] lg:text-[14px]">
                {selectedChat?.userData?._id?.sender?.firstName +
                  " " +
                  selectedChat?.userData?._id?.sender?.lastName}
              </h5>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="w-full h-[65vh] flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="h-[61vh] bg-white">
            <div className="h-full  flex justify-end items-end flex-col">
              {selectedChat?.userData?.messages?.length ? (
                <div
                  className="my-2 w-full overflow-y-auto px-2"
                  ref={messagesContainerRef}
                >
                  {selectedChat?.userData?.messages?.map((message, index) => {
                    if (
                      index > 0 &&
                      selectedChat?.userData?.messages[index - 1]?.direction
                        ?.type === message?.direction?.type
                    )
                      return (
                        <Message
                          key={index}
                          type={message?.direction?.type}
                          text={message?.message}
                          userProfile={message?.direction?.user?.photo}
                          isSending={message?.isSending}
                          customClasses={"!mt-[1px]"}
                          onDelete={() => {
                            if (!message._id) {
                              return;
                            }
                            setDeleteMessage({
                              modal: true,
                              data: {
                                message: [message._id],
                                targetChat:
                                  selectedChat.userData._id.sender._id,
                              },
                            });
                          }}
                        />
                      );
                    else
                      return (
                        <Message
                          key={index}
                          customClasses={"!mt-8"}
                          withAvatar={true}
                          type={message?.direction?.type}
                          text={message?.message}
                          isSending={message?.isSending}
                          userProfile={message?.direction?.user?.photo}
                          onDelete={() => {
                            if (!message._id) {
                              return;
                            }
                            setDeleteMessage({
                              modal: true,
                              data: {
                                message: [message._id],
                                targetChat:
                                  selectedChat.userData._id.sender._id,
                              },
                            });
                          }}
                        />
                      );
                  })}
                </div>
              ) : (
                <div className="w-full h-full text-center flex justify-center items-center">
                  <h1 className="text-gray-300 text-[18px] sm:text-[22px] md:text-[25px] lg:text-[30px]">
                    You have no message with this user.
                    <br /> Say "hi" to start new conversation
                  </h1>
                </div>
              )}

              <div className="w-full h-auto mb-2 overflow-hidden flex justify-center items-center !py-3">
                <form
                  onSubmit={handleSendMessage}
                  className="flex  justify-between border items-center relative rounded-full bg-[--bg-yellow-light] w-[93%] pl-2 pr-4"
                >
                  <input
                    type="text"
                    value={newMessageData.message}
                    name="message"
                    onChange={(e) => {
                      setNewMessagedata((pre) => {
                        return {
                          ...pre,
                          [e.target.name]: e.target.value,
                        };
                      });
                    }}
                    className="px-4 py-2 outline-none bg-inherit  w-[92%]"
                    placeholder="Write a message..."
                  />
                  <button
                    type="submit"
                    className="flex justify-center items-center text-[20px]"
                  >
                    <GrSend />
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Messages;
