import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import Loader from "../../components/Loading/Loader";
import StepsModal from "../../components/Modal/StepsModal";
import { useDispatch, useSelector } from "react-redux";
import { getClientsGoals } from "../../Redux/Actions";
import { isEmpty } from "lodash";
import GoalTableHead from "../../components/Table/GoalTableHead";
import GoalTableRow from "../../components/Table/GoalTableRow";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import CreateAssignGoal from "../../components/Modal/CreateAssignGoal";
import UpdateAssignGoal from "../../components/Modal/UpdateAssignGoal";
import DeleteGoal from "../../components/Modal/DeleteGoal";
import ReopenGoalModal from "../../components/Modal/ReopenGoalModal";

let Once = true;

const UserGoals = () => {
  const [loading, setLoading] = useState(false);
  // const [searchQuery, setSearchQuery] = useState("");
  const goalData = useSelector((state) => state.goals.data);
  const [stepModal, setStepModal] = useState(false);
  const [createGoalModal, setCreateGoalModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [updateSelfGoal, setUpdateSelfGoal] = useState({
    modal: false,
    data: null,
  });

  const [goalDelete, setGoalDelete] = useState({
    status: false,
    id: null,
  });

  const [reopenGoal, setReopenModal] = useState({
    status: false,
    data: null,
  });

  const dispatch = useDispatch();

  function handleCreateGoalModal() {
    setCreateGoalModal(!createGoalModal);
  }

  function handleModal(data) {
    setModalData(data);
    setStepModal(true);
  }

  function handleCloseModal() {
    setModalData(null);
    setStepModal(false);
  }

  function handleUpdateSelfModal(status = false, goalData = null) {
    setUpdateSelfGoal({
      modal: status,
      data: goalData,
    });
  }

  function handleDeleteGoal(status = false, goalId = null) {
    setGoalDelete({
      status: status,
      id: goalId,
    });
  }

  function handleReopenGoal(goalData) {
    setReopenModal({ status: !reopenGoal.status, data: goalData });
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(goalData)) {
        setLoading(true);
        dispatch(getClientsGoals()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      {reopenGoal.status && (
        <ReopenGoalModal
          open={reopenGoal.status}
          handleClose={() => {
            handleReopenGoal(null);
          }}
          data={reopenGoal.data}
        />
      )}
      {goalDelete.status && (
        <DeleteGoal
          open={goalDelete.status}
          data={goalDelete}
          handleModal={() => {
            handleDeleteGoal();
          }}
        />
      )}
      {stepModal ? (
        <StepsModal
          open={stepModal}
          handleClose={handleCloseModal}
          data={modalData}
        />
      ) : null}
      {createGoalModal ? (
        <CreateAssignGoal
          open={createGoalModal}
          handleClose={handleCreateGoalModal}
        />
      ) : null}
      {updateSelfGoal.modal && (
        <UpdateAssignGoal
          open={updateSelfGoal.modal}
          data={updateSelfGoal.data}
          handleClose={() => handleUpdateSelfModal()}
        />
      )}
      <HeaderData text="Customer Goals">
        <ButtonIcon
          onclick={handleCreateGoalModal}
          icon={<IoMdAdd />}
          text="Create Goal"
        />
      </HeaderData>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <div className="flex mt-3 justify-between items-center">
            <div className="relative w-[45%] md:w-[40%] lg:w-[35%] text-[--gray] bg-white border rounded-full">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button
                  type="submit"
                  className="p-1 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="w-3 h-3 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </span>
              <input
                type="search"
                name="q"
                className="py-2 text-[11px] sm:text-[13px] lg:text-sm w-[98%] bg-inherit rounded-md pl-8 sm:pl-10 lg:pl-12 outline-none text-black"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
          </div> */}

          {goalData?.length ? (
            <div className="dashboardTableWrapper">
              <table className="bg-white w-full min-w-[780px] border-collapse !overflow-x-auto mt-3">
                <GoalTableHead />
                <tbody>
                  {goalData
                    ?.sort(
                      (a, b) => parseInt(b.createdAt) - parseInt(a.createdAt)
                    )
                    .map((goal, index) => {
                      return (
                        <GoalTableRow
                          key={index}
                          goal={goal}
                          onUpdateSelfGoal={() => {
                            handleUpdateSelfModal(true, goal);
                          }}
                          OnClick={() => {
                            handleModal(goal);
                          }}
                          onDeleteGoal={() => {
                            handleDeleteGoal(true, goal._id);
                          }}
                          handleReopenGoal={() => {
                            handleReopenGoal(goal);
                          }}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No goal has assigned to you yet</p>
          )}
        </>
      )}
    </>
  );
};

export default UserGoals;
