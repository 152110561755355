import React from "react";
import defaultProfile from "../../Assets/default-profile.png";

const Avatar = ({ image }) => {
  return (
    <img
      className="w-[30px] h-[30px] rounded-full"
      src={image ? process.env.REACT_APP_IMAGE_URL + image : defaultProfile}
      alt="avatar"
    />
  );
};

export default Avatar;
