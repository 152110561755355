import { combineReducers } from "redux";
import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import taskReducer from "./taskReducer";
import goalsReducer from "./goalsReducer";
import eventReducer from "./eventReducer";
import CustomerDetailReducer from "./customerDetailReducer";
import chatListingReducer from "./chatListingReducer";
import chatAllUser from "./chatAllUser";

export default combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  tasks: taskReducer,
  goals: goalsReducer,
  events: eventReducer,
  customerDetail: CustomerDetailReducer,
  chatListing: chatListingReducer,
  allChatUser: chatAllUser,
});
