import { RouterProvider } from "react-router-dom";
import Routes from "./Routes";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin } from "./Redux/Actions";
import Loader from "./components/Loading/Loader";
import { useSocket } from "./context/socketContext";
let Once = true;

function App() {
  const [loading, setLoading] = useState(false);
  const staffData = useSelector((state) => state.auth.userData);
  const socket = useSocket();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(checkLogin())
      .then((res) => {
        if (socket.connected) {
          console.log(res.data._id);
          console.log("Connected");
          const data = {
            userId: res.data._id,
            socketId: socket.id,
          };
          socket.emit("create_connection", data);
        }
        setLoading(false);
      })
      .catch((err) => {
        dispatch({ type: "LOGOUT" });
        setLoading(false);
      });
  }, []);

  if (staffData && socket.connected) {
    window.addEventListener("load", () => {
      socket.emit("disconnectEvent", staffData._id);
    });
  }
  return (
    <>
      {loading ? (
        <section className="w-screen h-screen flex justify-center items-center">
          <Loader />
        </section>
      ) : (
        <RouterProvider router={Routes} />
      )}
    </>
  );
}

export default App;
