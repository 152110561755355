import React from 'react';
import Modal from './Modal';
import ButtonSmall from '../Buttons/ButtonSmall';

const TextModal = ({open,handleClose,description}) => {
    return (
        <Modal open={open} onclick={handleClose} headerText="Thank You">
        <div className='w-full px-2 pb-3'>
            <p>{description}</p>
        </div>
          <div className="flex justify-center items-center gap-4 mt-6 w-full">
          <ButtonSmall
                  text="Close"
                  onClick={handleClose}
                  customClasses="!bg-red-600 !border-red-600"
                />
          </div>

    </Modal>
    );
}

export default TextModal;
