import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import moment from "moment";
import { MdDeleteForever } from "react-icons/md";

const GoalTableRow = ({
  goal,
  OnClick,
  onUpdateSelfGoal,
  onDeleteGoal,
  handleReopenGoal,
  mainTab = true,
}) => {
  return (
    <tr className="border-b">
      <td className="flex justify-start items-center py-2 pl-4 min-w-max">
        <Link
          className="flex justify-start items-center"
          to={`/clients-detail/${goal.clientId._id}`}
        >
          <Avatar
            image={mainTab ? goal?.clientId?.photo[0] : goal?.clientId?.photo}
          />
          <div className=" ml-2 flex justify-center items-start flex-col">
            <h5 className="text-[14px]">
              {mainTab
                ? goal?.clientId?.firstName[0] +
                  " " +
                  goal?.clientId?.lastName[0]
                : goal?.clientId?.firstName + " " + goal?.clientId?.firstName}
            </h5>
          </div>
        </Link>
      </td>
      <td
        onClick={(e) => e.target.classList.toggle("!max-w-max")}
        className="text-[14px] text-left px-2 whitespace-nowrap text-ellipsis max-w-[100px]"
      >
        {goal?.name}
      </td>
      <td
        onClick={(e) => e.target.classList.toggle("!max-w-max")}
        className="text-[14px] text-left px-2 whitespace-nowrap text-ellipsis max-w-[100px]"
      >
        {goal?.goalType === "Financial"
          ? `Financial ($ ${goal?.amount})`
          : "Non Financial"}
      </td>
      <td
        onClick={(e) => e.target.classList.toggle("!max-w-max")}
        className="text-[14px] px-2 text-center whitespace-nowrap text-ellipsis max-w-[100px] text-lime-600"
      >
        {goal?.completed ? (
          goal.goalType === "Financial" ? (
            <span className="text-sm satoshi-500 bg-green-50 text-green-600 px-2 py-1">
              Completed
            </span>
          ) : (
            <button
              onClick={handleReopenGoal}
              className="text-sm satoshi-500 rounded-md bg-red-800 text-white px-2 py-1 cursor-pointer"
            >
              Re-Open
            </button>
          )
        ) : (
          <span className="text-sm satoshi-500 bg-red-50 text-red-500 px-2 py-1">
            Incomplete
          </span>
        )}
      </td>
      <td
        onClick={(e) => e.target.classList.toggle("!max-w-max")}
        className="text-[14px] text-left px-2 whitespace-nowrap text-ellipsis max-w-[150px]"
      >
        {moment(parseInt(goal?.createdAt)).format("DD MMM, YYYY") +
          " - " +
          moment(parseInt(goal?.endDate)).format("DD MMM, YYYY")}
      </td>
      <td className="text-center px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px] pl-2">
        {goal?.createdBy === "Staff" || goal?.createdBy === "Coach" ? (
          <div className="flex justify-center items-stretch gap-3">
            <button
              onClick={onUpdateSelfGoal}
              className="bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500"
            >
              Update Goal
            </button>

            <div
              onClick={onDeleteGoal}
              className="cursor-pointer border border-red-700 bg-red-700 text-white rounded-md px-2 py-1 transition-all ease-in-out duration-500 hover:text-red-700 hover:bg-white"
            >
              <MdDeleteForever className="text-base md:text-lg lg:text-xl xl:text-2xl" />
            </div>
          </div>
        ) : (
          <button
            onClick={OnClick}
            className="bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500"
          >
            {goal?.goalType === "Financial"
              ? "View Details"
              : goal?.steps?.length
              ? "Update Steps"
              : "Add Steps"}
          </button>
        )}
      </td>
    </tr>
  );
};

export default GoalTableRow;
