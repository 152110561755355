import React, { useState } from "react";
import HeaderData from "../Header/HeaderData";
import { useSelector } from "react-redux";
import StepsModal from "../Modal/StepsModal";
import GoalTableHead from "../Table/GoalTableHead";
import GoalTableRow from "../Table/GoalTableRow";
import UpdateAssignGoal from "../Modal/UpdateAssignGoal";
import DeleteGoal from "../Modal/DeleteGoal";
import ReopenGoalModal from "../Modal/ReopenGoalModal";

const Goals = () => {
  const { data } = useSelector((state) => state.customerDetail);
  const [stepModal, setStepModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [updateSelfGoal, setUpdateSelfGoal] = useState({
    modal: false,
    data: null,
  });
  const [goalDelete, setGoalDelete] = useState({
    status: false,
    id: null,
  });

  const [reopenGoal, setReopenModal] = useState({
    status: false,
    data: null,
  });

  function handleUpdateSelfModal(status = false, goalData = null) {
    setUpdateSelfGoal({
      modal: status,
      data: goalData,
    });
  }

  function handleModal(data) {
    setModalData(data);
    setStepModal(true);
  }

  function handleCloseModal() {
    setModalData(null);
    setStepModal(false);
  }

  function handleDeleteGoal(status = false, goalId = null) {
    setGoalDelete({
      status: status,
      id: goalId,
    });
  }

  function handleReopenGoal(goalData) {
    setReopenModal({ status: !reopenGoal.status, data: goalData });
  }

  return (
    <>
      {reopenGoal.status && (
        <ReopenGoalModal
          open={reopenGoal.status}
          customerTab={true}
          handleClose={() => {
            handleReopenGoal(null);
          }}
          data={reopenGoal.data}
        />
      )}
      {goalDelete.status && (
        <DeleteGoal
          open={goalDelete.status}
          data={goalDelete}
          customerTab={true}
          handleModal={() => {
            handleDeleteGoal();
          }}
        />
      )}
      {stepModal ? (
        <StepsModal
          open={stepModal}
          handleClose={handleCloseModal}
          data={modalData}
        />
      ) : null}

      {updateSelfGoal.modal && (
        <UpdateAssignGoal
          open={updateSelfGoal.modal}
          data={updateSelfGoal.data}
          customerTab={true}
          handleClose={() => handleUpdateSelfModal()}
        />
      )}
      <HeaderData text="Customer Goals" customClassess={"!my-3"} />
      {data?.goal?.length ? (
        <div className="dashboardTableWrapper">
          <table className="bg-white w-full min-w-[1000px] border-collapse !overflow-x-auto mt-3">
            <GoalTableHead />
            <tbody>
              {data?.goal
                ?.sort((a, b) => parseInt(b.createdAt) - parseInt(a.createdAt))
                .map((goal, index) => {
                  return (
                    <GoalTableRow
                      key={index}
                      goal={goal}
                      mainTab={false}
                      onUpdateSelfGoal={() => {
                        handleUpdateSelfModal(true, goal);
                      }}
                      OnClick={() => {
                        handleModal(goal);
                      }}
                      onDeleteGoal={() => {
                        handleDeleteGoal(true, goal._id);
                      }}
                      handleReopenGoal={() => {
                        handleReopenGoal(goal);
                      }}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>This customer don't have any goal yet</p>
      )}
    </>
  );
};

export default Goals;
