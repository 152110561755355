import React, { useEffect, useState } from "react";
import logo from "../../Assets/logoBlue.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { dashboardNavItems } from "../../Data/StaticData";
import { TbLogout2 } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { getUserUnreadMessages, logout } from "../../Redux/Actions";
import { useSocket } from "../../context/socketContext";

const DashboardSidebar = () => {
  const location = useLocation();
  const [active, setActive] = useState(0);
  const dispatch = useDispatch();
  const socket = useSocket();
  const staffData = useSelector((state) => state.auth.userData);
  const [count, setCount] = useState(0);

  function handleLogout() {
    socket.emit("disconnectEvent", staffData._id);
    dispatch(logout()).then(() => window.location.reload());
  }

  socket.on("messageCount", (data) => {
    setCount(data);
  });

  setInterval(() => {
    const data = {
      userId: staffData._id,
      socketId: socket.id,
    };
    socket.emit("create_connection", data);
  }, 50000);

  useEffect(() => {
    getUserUnreadMessages()
      .then((res) => {
        setCount(res?.count);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <div className="w-[70px] h-screen bg-[--white] transition-all ease-in-out duration-300 fixed top-0 left-0 overflow-y-auto  sm:w-[80px] md:border-none md:w-[95px] xl:w-[18%]">
        <Link
          to={"https://www.smartfbp.com"}
          className="w-full p-1 h-[70px]  flex justify-center items-center border-b border-gray-300"
        >
          <img
            src={logo}
            className="h-[15px] w-[35px] sm:h-[15px] md:w-[50px] md:h-[20px] lg:w-[80px] lg:h-[30px]"
            alt="Smart logo"
          />
        </Link>
        <ul className="mt-2 w-full px-1 xl:px-0 text-center text-[--gray] pb-8">
          {dashboardNavItems.map((navitem, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  setActive(index);
                }}
                className="mx-auto my-3 w-full xl:w-[80%] flex justify-center items-center "
              >
                <NavLink
                  to={navitem.url}
                  className={`${
                    location.pathname === navitem.url
                      ? "bg-black text-white"
                      : "text-black hover:bg-gray-200 transition-all ease-in-out duration-300 "
                  }
                  capitalize relative rounded-md w-full py-2 text-base sm:text-lg md:text-xl font-medium flex justify-start flex-col xl:flex-row items-center pl-0 xl:pl-3
                  `}
                >
                  {navitem.icon}
                  <span className="pl-0 xl:pl-2 text-[9px] md:text-[13px] lg:text-sm satoshi-500 relative">
                    {navitem.label}{" "}
                    {navitem.label.toLowerCase() === "inbox" && count > 0 ? (
                      <span className="text-[10px] font-bold bg-red-700 px-[6px] py-[2px] rounded-full text-white">
                        {count < 9 ? count : "9+"}
                      </span>
                    ) : null}
                  </span>
                </NavLink>
              </li>
            );
          })}
        </ul>
        <div>
          <li
            onClick={handleLogout}
            className="mx-auto w-full lg:w-[80%] flex justify-center items-center cursor-pointer"
          >
            <span className=" text-red-600 hover:bg-gray-200 transition-all ease-in-out duration-300  capitalize relative rounded-md w-full py-2 text-[21px] md:text-3xl lg:text-2xl flex justify-center flex-col xl:flex-row  lg:justify-start items-center pl-0 lg:pl-3">
              <TbLogout2 />
              <span className="pl-0 xl:pl-[15px] text-[12px] sm:text-[14px] md:text-[16px] lg:text-xl satoshi-400">
                Logout
              </span>
            </span>
          </li>
        </div>
      </div>
      <div className="w-[80px] sm:w-[80px] md:w-[90px] transition-all ease-in-out duration-300 min-h-sreen xl:w-[18%]"></div>
    </>
  );
};

export default DashboardSidebar;
